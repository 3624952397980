import React, { useState } from 'react'
import DataListSimples from '../../../components/layout/DataListSimples/DataListSimples'
import { createColumns } from '../../../helpers/tableConfigs'
import CommonHelper from '../../../helpers/CommonHelper'

const DadosFiliais = ({ form, edicaoHabilitada }) => {
  const [acessoEmpSelecionado, setAcessoEmpSelecionado] = useState(null)
  // const [visibleAcessoEmpModal, setVisibleAcessoEmpModal] = useState(false)

  const columns = createColumns([
    {
      field: 'nomraz_fil',
      header: 'Razão',
      width: '15%',
      alignText: 'text-left',
      headerClassName: 'text-left',
      sortable: false
    },
    {
      field: 'nomfan_fil',
      header: 'Fantasia',
      width: '10%',
      sortable: false
    },
    {
      field: 'apelid_fil',
      header: 'Apelido',
      width: '20%',
      sortable: false
    },
    {
      field: 'cnpj_fil',
      header: 'CNPJ',
      width: '15%',
      sortable: false,
      customBody: formatCnpj
    },
    {
      field: 'ativo_fil',
      header: 'Ativa?',
      width: '20%',
      sortable: false,
      customBody: formatAtivo,
      customClassName: getAtivoClass
    }
  ])

  function formatCnpj (data) {
    return CommonHelper.formatCnpj(data.cnpj_fil)
  }

  function getAtivoClass (data) {
    const className = { 1: 'ativa', 0: 'desativada' }
    return className[data.ativo_fil]
  }
  function formatAtivo (data) {
    return data.ativo_fil === 1 ? 'Sim' : 'Não'
  }
  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 flex justify-content-end">
      </div>
      <div className="field col-12">
        <DataListSimples
          value={form.values.filial}
          columns={columns}
          selected={acessoEmpSelecionado}
          onSelect={(e) => {
            setAcessoEmpSelecionado(e.value)
            // setVisibleAcessoEmpModal(true)
          }}
        />
      </div>
    </div>
  )
}

export default DadosFiliais
