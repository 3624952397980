export const getAtualizacaoDTO = (data = {}) => {
  return {
    codigo_atu: data.codigo_atu || null,
    codemp_atu: data.codemp_atu || null,
    datins_atu: data.datins_atu || null,
    codveratu_atu: data.codveratu_atu || null,
    codvernov_atu: data.codvernov_atu || null,
    datatu_atu: data.datatu_atu || null,
    datage_atu: data.datage_atu || null,
    codusu_atu: data.codusu_atu || null,
    finalizado_atu: data.finalizado_atu || null,
    observacao_atu: data.observacao_atu || null,
    VersaoAtual: data.VersaoAtual || null,
    VersaoNova: data.VersaoNova || null,
    usuarioSol: data.usuarioSol || null,
    usuarioAtu: data.usuarioAtu || null

  }
}

export const postAtualizacaoDTO = (data) => {
  return {
    codigo_atu: data.codigo_atu || null,
    codemp_atu: data.codemp_atu || null,
    datins_atu: data.datins_atu || null,
    codveratu_atu: data.codveratu_atu || null,
    codvernov_atu: data.codvernov_atu || null,
    datatu_atu: data.datatu_atu || null,
    datage_atu: data.datage_atu || null,
    codusu_atu: data.codusu_atu || null,
    finalizado_atu: data.finalizado_atu || null,
    observacao_atu: data.observacao_atu || null,
    VersaoAtual: data.VersaoAtual || null,
    VersaoNova: data.VersaoNova || null,
    usuarioSol: data.usuarioSol || null,
    usuarioAtu: data.usuarioAtu || null
  }
}
