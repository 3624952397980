import ServiceBase from '../base/ServiceBase'
export default class VersaoService {
  static async sincronizarVersao () {
    return await ServiceBase.postBase('versoes/sincronizar')
  }

  static async filter (query, page, perPage) {
    return await ServiceBase.getBase(`versoes?${query}&page=${page}&perPage=${perPage}`)
  }

  static async getAll (page, perPage) {
    return await ServiceBase.getBase(`versoes?page=${page}&perPage=${perPage}`)
  }
}
