import React, { Component } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { isLoggedIn } from './helpers/auth'
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import './layout/layout.scss'
import './App.scss'

import { Provider } from 'react-redux'
import PublicContent from './components/layout/PublicContent'
import AppContent from './components/layout/AppContent'
import { Login } from './views/login/Login'
import { Usuario } from './views/cadastro/usuario/Usuario'
import { UsuarioList } from './views/cadastro/usuario/UsuarioList'
import { GrupoUsuario } from './views/cadastro/grupousuario/GrupoUsuario'
import { GrupoUsuarioList } from './views/cadastro/grupousuario/GrupoUsuarioList'
import ErrorPage from './components/error/ErrorPage'
import { addLocale } from 'primereact/api'
import EmpresaList from './views/cadastro/empresa/EmpresaList'
import Empresa from './views/cadastro/empresa/Empresa'
import Home from './views/home/Home'
import { store } from './redux/store'
import { TipoAcessoList } from './views/cadastro/tipoacesso/TipoAcessoList'
import { TipoAcesso } from './views/cadastro/tipoacesso/TipoAcesso'
import VersoesList from './views/versoes/VersoesList'
import ChaveAcessoList from './views/chaveAcesso/ChaveAcessoList'
import Atualizacao from './views/atualização/Atualizacao'
import ConfiguracaoList from './forms/cadastro/configuracao/ConfiguracaoList'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    if (!isLoggedIn()) {
      return (
        <PublicContent>
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        </PublicContent>
      )
    }
    return (
      <AppContent {...props} >
        <Component {...props} />
      </AppContent>
    )
  }} />
)

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    return (
      <PublicContent>
        <Component {...props} />
      </PublicContent>
    )
  }} />
)

addLocale('br', {
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  clear: 'Limpar'
})

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <PublicRoute exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/empresas" component={EmpresaList} />
            <PrivateRoute exact path="/chaveacesso" component={ChaveAcessoList} />
            <PrivateRoute exact path="/empresa" component={Empresa} />
            <PrivateRoute exact path="/empresa/:id" component={Empresa} />
            <PrivateRoute exact path="/versoes" component={VersoesList} />
            <PrivateRoute exact path="/atualizacao" component={Atualizacao} />
            <PrivateRoute exact path="/integracao" component={ConfiguracaoList} />
            <PrivateRoute exact path="/usuarios" component={UsuarioList} />
            <PrivateRoute exact path="/usuario" component={Usuario} />
            <PrivateRoute exact path="/usuario/:id" component={Usuario} />
            <PrivateRoute exact path="/gruposusuarios" component={GrupoUsuarioList} />
            <PrivateRoute exact path="/grupousuario" component={GrupoUsuario} />
            <PrivateRoute exact path="/grupousuario/:id" component={GrupoUsuario} />
            <PrivateRoute exact path="/tiposacessos" component={TipoAcessoList} />
            <PrivateRoute exact path="/tipoacesso" component={TipoAcesso} />
            <PrivateRoute exact path="/tipoacesso/:id" component={TipoAcesso} />
            <Route path="*" render={props => <ErrorPage throughRoute {...props} />} />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

export default App
