import { postMenuAcessoGrupoDTO } from '../../dtos/cadastro/MenuAcessoGrupoDTO'
import ServiceBase from '../base/ServiceBase'

export default class MenuAcessoGrupoService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`menus?page=${page}&perPage=${perPage}`)
  }

  static async getMenuAcesso () {
    const res = await ServiceBase.getBase('menus/menuacessogrupo')
    return res
  }

  static async getMenuAcessoByGrupo (idGrupo) {
    const res = await ServiceBase.getBase(`menus/menuacessogrupo/${idGrupo}`)
    return res
  }

  static async save (data) {
    return await ServiceBase.postBase('menus', postMenuAcessoGrupoDTO(data))
  }
}
