export const getMenuAcessoGrupoDTO = (data = {}) => {
  return {

    codigo_mag: data.codigo_mag || null,
    codmen_mag: data.codmen_mag || null,
    codgru_mag: data.codgru_mag || null
  }
}

export const postMenuAcessoGrupoDTO = (data = {}) => {
  return {
    codigo_mag: data.codigo_mag || null,
    codmen_mag: data.codmen_mag || null,
    codgru_mag: data.codgru_mag || null
  }
}
