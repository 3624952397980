import { postEmpresaDTO } from '../../dtos/cadastro/EmpresaDTO'
import ApiMovidesk from '../../helpers/ApiMovidesk'
import ServiceBase from '../base/ServiceBase'

export default class EmpresaService {
  async get (id) {
    let retorno = await ServiceBase.getBase(`empresa/${id}`)
    return retorno
  }

  async getAll (page, perPage) {
    let dados = await ServiceBase.getBase(`empresas?page=${page}&perPage=${perPage}`)
    return dados
  }

  async filter (query, page, perPage) {
    let dados = await ServiceBase.getBase(`empresas?${query}&page=${page}&perPage=${perPage}`)
    return dados
  }

  static async save (data) {
    let dados = await ServiceBase.postBase('empresa', postEmpresaDTO(data))
    return dados
  }

  async getEmpresaMovidesk (cpfCnpj) {
    //return await getBase(`&$filter=cpfCnpj eq '${cpfCnpj}'`)
    let dados = await ApiMovidesk.getPessoaMovidesk(cpfCnpj)
    return dados
  }

  async getTicketsMovidesk () {
    let dados = await ApiMovidesk.getTicketsMovidesk()
    return dados
  }

  static async downloadWts (id) {
    let texto = await ServiceBase.getBase(`empresa/${id}/downloadwts`)

    return texto
  }
}
