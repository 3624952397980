import axios from 'axios'
import ServiceBase from '../services/base/ServiceBase'
import { startLoading, stopLoading } from '../redux/actions'
import { dispatch } from '../redux/store'

export const APIMOVIDESK_URL = 'https://api.movidesk.com/public/v1/'

export default class ApiMovidesk {
  static async getPessoaMovidesk (cnpj, loading = true) {
    let id = 1
    let config = await ServiceBase.getBase(`configuracao/${id}`)

    let connection = axios.create({
      baseURL: APIMOVIDESK_URL + `persons?token=${config.valor_con}&$filter=cpfCnpj%20eq%20%27${cnpj}%27`,
      responseType: 'json',
      headers: {
        LoadingState: loading
      }
    })

    connection.interceptors.request.use(function (config) {
      // Do something before request is sent
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }
      return config
    }, function (error) {
      // Do something with request error
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }

      return Promise.reject(error)
    })

    connection.interceptors.response.use(function (response) {
      // Any status code that lie WITHIN the range of 2xx cause this function to trigger
      // Do something with response data
      dispatch(stopLoading())
      return response
    }, function (error) {
      // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
      // Do something with response error
      dispatch(stopLoading())
      return Promise.reject(error)
    })

    return await connection.get()
  }

  static async getTicketsMovidesk (loading = true) {
    let id = 1
    let config = await ServiceBase.getBase(`configuracao/${id}`)

    let connection = axios.create({
      baseURL: APIMOVIDESK_URL + `tickets?token=${config.valor_con}&$select=id,status,justification,origin&$filter=(justification eq 'Versão liberada')&$expand=clients`,
      responseType: 'json',
      headers: {
        LoadingState: loading
      }
    })

    connection.interceptors.request.use(function (config) {
      // Do something before request is sent
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }
      return config
    }, function (error) {
      // Do something with request error
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }

      return Promise.reject(error)
    })

    connection.interceptors.response.use(function (response) {
      // Any status code that lie WITHIN the range of 2xx cause this function to trigger
      // Do something with response data
      dispatch(stopLoading())
      return response
    }, function (error) {
      // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
      // Do something with response error
      dispatch(stopLoading())
      return Promise.reject(error)
    })

    return await connection.get()
  }

  static async getTicketsPeloIdPessoaMovidesk (idPessoa, loading = true) {
    let id = 1
    let config = await ServiceBase.getBase(`configuracao/${id}`)

    let connection = axios.create({
      baseURL: APIMOVIDESK_URL + `tickets?token=${config.valor_con}&$filter=clients/any(client: client/id eq '${idPessoa}') and justification eq 'Versão liberada'&$select=id,status,justification&$expand=clients`,
      responseType: 'json',
      headers: {
        LoadingState: loading
      }
    })

    connection.interceptors.request.use(function (config) {
      // Do something before request is sent
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }
      return config
    }, function (error) {
      // Do something with request error
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }

      return Promise.reject(error)
    })

    connection.interceptors.response.use(function (response) {
      // Any status code that lie WITHIN the range of 2xx cause this function to trigger
      // Do something with response data
      dispatch(stopLoading())
      return response
    }, function (error) {
      // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
      // Do something with response error
      dispatch(stopLoading())
      return Promise.reject(error)
    })

    return await connection.get()
  }

  static async getPessoasVinculadasEmpresaMovidesk (idEmpresa, loading = true) {
    let id = 1
    let config = await ServiceBase.getBase(`configuracao/${id}`)

    let connection = axios.create({
      baseURL: APIMOVIDESK_URL + `persons?token=${config.valor_con}&$select=id,corporateName,businessName&$filter=relationships/any(org : org/id eq '${idEmpresa}')`,
      responseType: 'json',
      headers: {
        LoadingState: loading
      }
    })

    connection.interceptors.request.use(function (config) {
      // Do something before request is sent
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }
      return config
    }, function (error) {
      // Do something with request error
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }

      return Promise.reject(error)
    })

    connection.interceptors.response.use(function (response) {
      // Any status code that lie WITHIN the range of 2xx cause this function to trigger
      // Do something with response data
      dispatch(stopLoading())
      return response
    }, function (error) {
      // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
      // Do something with response error
      dispatch(stopLoading())
      return Promise.reject(error)
    })

    return await connection.get()
  }

  static async getNomeEmpresa (nomeEmpresa, loading = true) {
    let id = 1
    let config = await ServiceBase.getBase(`configuracao/${id}`)
    // https://api.movidesk.com/public/v1/tickets?token=ec673f86-3d7a-4045-afc0-4b453e148678&$select=id,status,justification&$expand=clients($expand=organization($select=id,businessName)),clients($select=businessName)&$filter=clients/any(a: a/organization/businessName eq 'Pedrotti Implementos')  and (justification eq 'Versão liberada')
    let connection = axios.create({
      baseURL: APIMOVIDESK_URL + `tickets?token=${config.valor_con}&$select=id,status,justification&$expand=clients($expand=organization($select=id,businessName)),clients($select=businessName)&$filter=clients/any(a: a/organization/businessName eq '${nomeEmpresa}')  and (justification eq 'Versão liberada')`,
      responseType: 'json',
      headers: {
        LoadingState: loading
      }
    })

    connection.interceptors.request.use(function (config) {
      // Do something before request is sent
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }
      return config
    }, function (error) {
      // Do something with request error
      if (config.headers['LoadingState']) {
        dispatch(startLoading())
      }

      return Promise.reject(error)
    })
    connection.interceptors.response.use(function (response) {
      // Any status code that lie WITHIN the range of 2xx cause this function to trigger
      // Do something with response data
      dispatch(stopLoading())
      response?.data.forEach(empresa => {
        ApiMovidesk.updateTicketMovidesk(empresa.id, {
          justification: 'Versão atualizada',
          status: 'Em pausa'
        })
      })
      return response
    }, function (error) {
      // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
      // Do something with response error
      dispatch(stopLoading())
      return Promise.reject(error)
    })

    return await connection.get()
  }

  static async updateTicketMovidesk (idTicket, newData) {
    let id = 1
    let config = await ServiceBase.getBase(`configuracao/${id}`)

    let connection = axios.create({
      baseURL: APIMOVIDESK_URL + `tickets?token=${config.valor_con}&id=${idTicket}`,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    connection.interceptors.request.use(function (config) {
      // Do something before request is sent

      dispatch(startLoading())
      return config
    }, function (error) {
      // Do something with request error
      dispatch(startLoading())
      return Promise.reject(error)
    })

    connection.interceptors.response.use(function (response) {
      // Any status code that lie WITHIN the range of 2xx cause this function to trigger
      // Do something with response data
      dispatch(stopLoading())
      return response
    }, function (error) {
      // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
      // Do something with response error
      dispatch(stopLoading())
      return Promise.reject(error)
    })
    return await connection.patch('', newData)
  }

  ///https://api.movidesk.com/public/v1/tickets?token=ec673f86-3d7a-4045-afc0-4b453e148678&$filter=clients/any(client: client/id eq '2016608473') and justification eq 'Versão liberada'&$select=id,status,justification&$expand=clients filtra pelo id do cliente e o status

  ///https://api.movidesk.com/public/v1/persons?token=ec673f86-3d7a-4045-afc0-4b453e148678&$select=id,corporateName,businessName&$filter=relationships/any(org : org/id eq '1493872460')
  /// filtra o id da empresa e retorna as pessoas ligadas a empresa
}
