import ServiceBase from '../base/ServiceBase'

export default class ChaveAcessoSerivice {
  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`chaveacesso?${query}&page=${page}&perPage=${perPage}`)
  }

  async getAll (page, perPage) {
    let dados = await ServiceBase.getBase(`chaveacesso?page=${page}&perPage=${perPage}`)
    return dados
  }
}
