import React from 'react'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Crud } from '../../../classes/Crud'
import { getTipoAcessoDTO } from '../../../dtos/cadastro/TipoAcessoDTO'
import TipoAcessoService from '../../../services/cadastro/TipoAcessoService'
import TipoAcessoForm from '../../../forms/cadastro/tipoacesso/TipoAcessoForm'

export class TipoAcesso extends Crud {
  constructor (props) {
    super(props, getTipoAcessoDTO, TipoAcessoService, 'tiposacessos')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <TipoAcessoForm
              model={model}
              isNew={isNew}
            />
          )}
        </Container>
      </Page>
    )
  }
}
