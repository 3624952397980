import { postUsuarioDTO } from '../../dtos/cadastro/UsuarioDTO'
import ServiceBase from '../base/ServiceBase'

export default class UsuarioService {
  async get (id) {
    let usuario = await ServiceBase.getBase(`usuario/${id}`)

    return usuario
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`usuarios?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`usuarios?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('usuario', postUsuarioDTO(data))
  }

  static async getUsuarioLogado () {
    return await ServiceBase.getBase('usuario/get/logado')
  }

  static async changePassword (data) {
    return await ServiceBase.postBase('usuario/alterarSenha', data)
  }

  static async changePasswordBeforeLogin (data) {
    return await ServiceBase.postBase('alterarSenhaAntesLogin', data)
  }
}
