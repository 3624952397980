export const getAcessoEmpDTO = (data = {}) => {
  return {
    codigo_ace: data.codigo_ace || null,
    nome_ace: data.nome_ace || '',
    codtia_ace: data.codtia_ace || null,
    codemp_ace: data.codemp_ace || null,
    ip_ace: data.ip_ace || '',
    usuario_ace: data.usuario_ace || '',
    senha_ace: data.senha_ace || '',
    senhacomp_ace: data.senhacomp_ace || '',
    datins_ace: data.datins_ace || null,
    datalt_ace: data.datalt_ace || null,
    tipoacesso: data.tipoacesso || null,
    usuarioInsercao: data.usuarioInsercao || null,
    usuarioAlteracao: data.usuarioAlteracao || null,
    gateway_ace: data.gateway_ace || null
  }
}

export const postAcessoEmpDTO = (data) => {
  return {
    codigo_ace: data.codigo_ace || null,
    nome_ace: data.nome_ace || '',
    codtia_ace: data.codtia_ace || null,
    codemp_ace: data.codemp_ace || null,
    ip_ace: data.ip_ace || '',
    usuario_ace: data.usuario_ace || '',
    senha_ace: data.senha_ace || '',
    senhacomp_ace: data.senhacomp_ace || '',
    datins_ace: data.datins_ace || null,
    datalt_ace: data.datalt_ace || null,
    tipoacesso: data.tipoacesso || null,
    usuarioInsercao: data.usuarioInsercao || null,
    usuarioAlteracao: data.usuarioAlteracao || null,
    gateway_ace: data.gateway_ace || null
  }
}
