import ServiceBase from '../base/ServiceBase'

export default class ConfigService {
  async get (id) {
    let retorno = await ServiceBase.getBase(`config/${id}`)
    return retorno
  }

  async getConfigEmpresa (id, codemp) {
    let dados = await ServiceBase.getBase(`config-empresa?numeroConfig=${id}&codemp=${codemp}`)
    return dados
  }

  async filter (query, page, perPage) {
    let dados = await ServiceBase.getBase(`config?${query}&page=${page}&perPage=${perPage}`)
    return dados
  }

  async getAll (id, page, perPage) {
    let dados = await ServiceBase.getBase(`config?codemp=${id}&page=${page}&perPage=${perPage}`)
    return dados
  }

  async getConfigs (numeroCon, page, perPage) {
    let dados = await ServiceBase.getBase(`config?numeroCon=${numeroCon}&page=${page}&perPage=${perPage}`)
    return dados
  }
}
