import { Column } from 'primereact/column'
import React from 'react'
import List from '../../../classes/List'
import SearchInput from '../../../components/inputs/SearchInput'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage } from '../../../components/utils/Message'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { getEmpresaDTO } from '../../../dtos/cadastro/EmpresaDTO'
import { getConfigDTO } from '../../../dtos/configuracao/ConfigDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatCnpj } from '../../../helpers/formaters'
import EmpresaService from '../../../services/cadastro/EmpresaService'
import ConfigService from '../../../services/configuracao/ConfigService'
import ConfiguracaoModal from './ConfiguracaoModal'
export default class ConfiguracaoPorEmp extends List {
  constructor (props) {
    super(
      props,
      getConfigDTO,
      ConfigService,
      'config',
      'codigo_con',
      '',
      137
    )

    this.state = {
      ...this.state,
      empresa: [],
      sugestaoEmpresa: '',
      visibleModal: false,
      configSelecionada: [],
      filter: '',
      first: 1,
      page: 1,
      loading: true
    }

    this.setColumns([

      {
        key: 'numero_con',
        component: (
          <Column
            key={'numero_con'}
            className="p-col-1"
            field="numero_con"
            header="Número"
            sortable
            headerClassName="numero_con" />
        )
      }, {
        key: 'descri_con',
        component: (
          <Column
            key={'descri_con'}
            className="p-col-4"
            field="descri_con"
            header="Descrição"
            sortable
            headerClassName="descri_con" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            key={'valor_con'}
            className="p-col-4"
            field="valor_con"
            header="Valor"
            body={(data) => <ResponsiveColumn
              column="nomraz_emp"
              value={this.handleValorCon(data.valor_con)}
            />}
            sortable
            headerClassName="valor_con" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            key={'nomraz_emp'}
            className="p-col-1"
            field="nomraz_emp"
            body={(data) => <ResponsiveColumn
              column="nomraz_emp"
              value={data.filial?.apelid_fil || data.nomfan_fil || 'Multi empresa'}
            />}
            header="Filial"
            sortable
            headerClassName="nomraz_emp" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            key={'nomraz_emp'}
            className="p-col-1"
            field="nomraz_emp"
            body={(data) => <ResponsiveColumn
              column="nomraz_emp"
              value={data.empresa.nomfan_emp || data.empresa.nomraz_emp}
            />}
            header="Empresa"
            sortable
            headerClassName="nomraz_emp" />
        )
      }

    ])
  }

  handleValorCon = (valor) => {
    if (String(valor).toLocaleUpperCase() === '.T.') {
      valor = 'Sim'
    } else if (String(valor).toLocaleUpperCase() === '.F.') {
      valor = 'Não'
    } else {
      valor = valor
    }
    return valor
  }

  async componentDidMount () {
    await this.getConfig(this.state.empresa?.codigo_emp)
  }

  handleEmpresa = async (e) => {
    let emp = e.value
    this.setState({ empresa: emp })
    this.getConfig(emp.codigo_emp)
  }

  setEmpresa = async (e) => {
    let emp = e.value
    this.setState({ empresa: emp })
    this.sugerirEmpresa(emp)
    if (emp === '') {
      this.getConfig(false)
    }
  }

  getAll = async (codemp) => {
    try {
      let config = new ConfigService()
      let response = await config.getAll(codemp, 1, 10)
      this.setRetorno(response)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os registros!')
    }
  }

  setRetorno = (response) => {
    this.setState({
      list: response.data,
      totalRecords: response.meta?.total,
      totalPages: response.meta?.last_page,
      quantidades: response.quantidades
    })
    this.setState({ loading: false })
  }

  async getConfig (codemp) {
    try {
      let config = new ConfigService()
      let response

      if (typeof this.state.filter === 'function' || this.state.filter === '') {
        response = await config.filter(`${codemp ? `codemp=${codemp} ` : ''}`, this.state.page, 10)
      } else {
        response = await config.filter(`descricaoEmpresa=${this.state.filter} ${codemp ? `&codemp=${codemp}` : ''}`, this.state.page, this.state.rows)
      }
      this.setRetorno(response)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os registros!')
    }
  }

  sugerirEmpresa = async () => {
    try {
      const empresaAtualizacao = new EmpresaService()

      const empresaSugerida = (
        await empresaAtualizacao.filter(`descricaoFiltro=${this.state.empresa}`)
      ).data

      this.setState({ sugestaoEmpresa: empresaSugerida })
    } catch (error) {
      showErrorMessage(
        error.message || 'Ocorreu um erro inesperado ao sugerir uma configuração!'
      )
    }
  }

      handleChangeFilter = event => {
        let filter = this.state.filter
        filter = event.target.value
        this.setState({ filter })
      }

      toFilter () {
        let filtro
        if (this.state.empresa?.codigo_emp) {
          filtro = `descricaoFiltro=${this.state.filter}&codemp=${this.state.empresa?.codigo_emp}`
        } else {
          filtro = `descricaoEmpresa=${this.state.filter}`
        }
        return filtro
      }

      onFilter = async () => {
        try {
          this.setState({ loading: true })
          let config = new ConfigService()
          let response = await config.filter(this.toFilter(), this.state.page, this.state.rows)

          this.setRetorno(response)
        } catch (error) {
          showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
        }
      }

      onPageChange = async data => {
        const first = data.first
        const page = data.page + 1
        await this.setState({ first, page })
        this.onFilter()
      }

      render () {
        const { rows, totalRecords, first, page, list, selected, empresa, sugestaoEmpresa, loading } = this.state
        const classNameLabel = CommonHelper.isDesktop() ? 'w-3 my-0 mx-2' : 'w-100'
        return (
          <Page>
            <div className="flex align-items-center page-header">
              <div className={`field col-12 my-0 py-0 m-1 ${classNameLabel}`}>
                <SearchInput
                  label='Empresa'
                  AutoComplete
                  field='nomfan_emp'
                  value={empresa || empresa?.nomfan_emp || empresa?.nomraz_emp }
                  suggestions={sugestaoEmpresa}
                  completeMethod={this.sugerirEmpresa}
                  onChange={this.setEmpresa}
                  onSelect={(e) => this.handleEmpresa(e)}
                  className={'w-full'}
                  FiltroModal
                  filtroTitle='Pesquisar Empresa'
                  service={EmpresaService}
                  model={getEmpresaDTO}
                  columns={[
                    { campo: 'nomfan_emp', nome: 'Nome' },
                    { campo: 'cnpj_emp', nome: 'CNPJ', format: formatCnpj }
                  ]}
                />
              </div>
            </div>
            <Container>
              <DataList
                loading={loading}
                data={list}
                selected={selected}
                rows={rows}
                onSelect={this.onSelect}
                totalRecords={totalRecords}
                first={first}
                onPage={this.onPageChange}
                page={page}
                onRowSelect={() => this.setState({ visibleModal: true })}
                showFilter
                filterPlaceholder="Procurar empresas"
                filterName="descricaoEmpresa"
                responsive
                filterOnChange={this.handleChangeFilter}
                onFilter={this.onFilter}
              >
                {this.columns.map((column) => column.component)}
              </DataList>
            </Container>
            <ConfiguracaoModal
              visible={this.state.visibleModal}
              onHide={() => {
                this.setState({ configSelecionada: [] })
                this.setState({ visibleModal: false })
                this.setState({ selected: false })
              }}
              page={ this.state.page}
              rows={this.state.rows}
              configSelecionada={selected}
            />
          </Page>
        )
      }
}
