import React from 'react'
import GrupoUsuarioForm from '../../../forms/cadastro/grupousuario/GrupoUsuarioForm'
import GrupoUsuarioService from '../../../services/cadastro/GrupoUsuarioService'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Crud } from '../../../classes/Crud'
import { getGrupoUsuarioDTO } from '../../../dtos/cadastro/GrupoUsuarioDTO'

export class GrupoUsuario extends Crud {
  constructor (props) {
    super(props, getGrupoUsuarioDTO, GrupoUsuarioService, 'gruposusuarios')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <GrupoUsuarioForm
              model={model}
              isNew={isNew}
            />
          )}
        </Container>
      </Page>
    )
  }
}
