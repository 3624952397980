// Importação das funções necessárias do Redux e redux-axios-middleware
import { createStore, applyMiddleware } from 'redux'
import axiosMiddleware from 'redux-axios-middleware'

// Importação do reducer principal da aplicação
import reducer from './reducer'

// Importação da configuração do axios que será utilizada pelo middleware
import { server } from '../helpers/server'

// Criação do Redux store com o reducer principal, aplicando o middleware
// redux-axios-middleware configurado com uma instância do axios.
// A função server() deve retornar uma instância configurada do axios.
const store = createStore(
  reducer,
  applyMiddleware(axiosMiddleware(server()))
)

// Função para despachar ações para o Redux store.
// É uma abstração sobre store.dispatch, permitindo ações a serem despachadas diretamente.
export const dispatch = (action) => {
  store.dispatch(action)
}

// Função para obter o estado atual armazenado no Redux store.
// É uma abstração sobre store.getState, facilitando o acesso ao estado atual.
export const getStore = () => {
  return store.getState()
}

export { store } // Exporta o store para uso em outros lugares da aplicação.
