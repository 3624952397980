import { postGrupoUsuarioDTO } from '../../dtos/cadastro/GrupoUsuarioDTO'
import ServiceBase from '../base/ServiceBase'

export default class GrupoUsuarioService {
  async get (id) {
    console.log('to no get do grupo usuario service')
    let resultado = await ServiceBase.getBase(`grupousuario/${id}`)
    return resultado
  }

  async getAll (page, perPage) {
    console.log('to no get all do grupo usuario service')
    return await ServiceBase.getBase(`gruposusuarios?page=${page}&perPage=${perPage}`)
  }

  async getTodos () {
    console.log('to no get todos do grupo usuario service')
    const res = await ServiceBase.getBase('gruposusuarios/todos')
    return res
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`gruposusuarios?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('grupousuario', postGrupoUsuarioDTO(data))
  }
}
