import React, { useState } from 'react'
import AppButton from '../../../components/layout/AppButton'
import DataListSimples from '../../../components/layout/DataListSimples/DataListSimples'
import CommonHelper from '../../../helpers/CommonHelper'
import { createColumns } from '../../../helpers/tableConfigs'
import DadosAcessoEmpresaModal from './DadosAcessoEmpresaModal'

const DadosAcessoEmpresa = ({ form, edicaoHabilitada }) => {
  const [acessoEmpSelecionado, setAcessoEmpSelecionado] = useState(null)
  const [visibleAcessoEmpModal, setVisibleAcessoEmpModal] = useState(false)

  const columns = createColumns([
    {
      field: 'nome_ace',
      header: 'Nome',
      width: '15%',
      alignText: 'text-left',
      headerClassName: 'text-left',
      customBody: formatNomeAcesso,
      sortable: false
    },
    {
      field: 'nome_tia',
      header: 'Tipo Acesso',
      customBody: getNomeTipoAcesso,
      width: '10%',
      sortable: false
    },
    {
      field: 'ip_ace',
      header: 'IP',
      width: '20%',
      sortable: false
    },
    {
      field: 'usuario_ace',
      header: 'Usuário',
      width: '15%',
      sortable: false
    },
    {
      field: 'senha_ace',
      header: 'Senha Acesso',
      width: '20%',
      sortable: false
    },
    {
      field: 'senhacomp_ace',
      header: 'Senha do computador',
      width: '15%',

      sortable: false
    },
    {
      field: 'datalt_ace',
      header: 'Alterado em',
      customBody: formatDataVencimento,
      width: '10%',
      sortable: false
    },
    {
      field: 'usuarioAlteracao',
      header: 'Alterado por',
      width: '10%',
      sortable: false
    }

  ])

  function formatDataVencimento (data) {
    return CommonHelper.dateToBrazilian(data.datalt_ace)
  }

  function formatNomeAcesso (data) {
    return data.nome_ace.toUpperCase()
  }
  function getNomeTipoAcesso (data) {
    return data.tipoacesso.nome_tia
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 flex justify-content-end">
        <AppButton
          type="button"
          label="Adicionar"
          icon="pi pi-plus"
          onClick={() => setVisibleAcessoEmpModal(true)}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12">
        <DataListSimples
          value={form.values.acessosemp}
          columns={columns}
          selected={acessoEmpSelecionado}
          onSelect={(e) => {
            setAcessoEmpSelecionado(e.value)
            setVisibleAcessoEmpModal(true)
          }}
        />
      </div>
      <DadosAcessoEmpresaModal
        visible={visibleAcessoEmpModal}
        onHide={() => {
          setAcessoEmpSelecionado(null)
          setVisibleAcessoEmpModal(false)
        }}
        empresaForm={form}
        edicaoHabilitada={edicaoHabilitada}
        acessoEmpSelecionado={acessoEmpSelecionado}
      />
    </div>
  )
}

export default DadosAcessoEmpresa
