// import {postAtualizacaoDTO} from '../../dtos/cadastro/AtualizacaoDTO'
import ServiceBase from '../base/ServiceBase'

export default class AtualizacaoService {
  static async get (id) {
    let resultado = await ServiceBase.getBase(`atualizacao/${id}`)
    return resultado
  }

  async filter (query, page, perPage) {
    let dados = await ServiceBase.getBase(`atualizacao?${query}&page=${page}&perPage=${perPage}`)
    return dados
  }

  async getAll (page, perPage) {
    let dados = await ServiceBase.getBase(
      `atualizacao?page=${page}&perPage=${perPage}`
    )
    return dados
  }

  static async getTodos (page, perPage) {
    let dados = await ServiceBase.getBase(
      `atualizacao?page=${page}&perPage=${perPage}`
    )
    return dados
  }

  static async delete (id) {
    return await ServiceBase.deleteBase(`atualizacao/${id}`)
  }

  static async save (empresa, versaoAtu, versaoNov, dataAgendamento, observacao, usuarioInserido, codigoAtu, atualizadoPor, atualizado) {
    let dados = await ServiceBase.postBase('atualizacao', {
      codigo_atu: codigoAtu,
      codemp_atu: empresa,
      codvernov_atu: versaoNov,
      datage_atu: dataAgendamento,
      observacao_atu: observacao,
      codususol_atu: usuarioInserido,
      codveratu_atu: versaoAtu,
      codusu_atu: atualizadoPor || null,
      datatu_atu: atualizado
    })
    return dados
  }
}
