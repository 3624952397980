import React, { Component } from 'react'
import { AppTopbar } from './AppTopbar'
import { AppProfile } from './AppProfile'
import { AppMenu } from './AppMenu'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { ProgressSpinner } from 'primereact/progressspinner'
import Message from '../utils/Message'
import { BsFillFilePersonFill, BsFillGearFill, BsTools } from 'react-icons/bs'
import { VscCircleFilled } from 'react-icons/vsc'
import UsuarioService from '../../services/cadastro/UsuarioService'
import MenuAcessoGrupoService from '../../services/cadastro/MenuAcessoGrupoService'
import { setUsuarioLogado } from '../../redux/actions'
import { dispatch, getStore } from '../../redux/store'
import VersaoService from '../../services/cadastro/VersaoService'

class AppContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      loading: true,
      permissaoAcesso: []
    }

    this.onWrapperClick = this.onWrapperClick.bind(this)
    this.onToggleMenu = this.onToggleMenu.bind(this)
    this.onSidebarClick = this.onSidebarClick.bind(this)
    this.onMenuItemClick = this.onMenuItemClick.bind(this)
  }

  async componentDidMount () {
    this.loadInitialState().then(() => {
      this.setState({ loading: false })
    })
  }

  async loadInitialState () {
    await UsuarioService.getUsuarioLogado().then(usuario => {
      dispatch(setUsuarioLogado(usuario))
    })

    let menuAcesso = await MenuAcessoGrupoService.getMenuAcesso()

    this.setState({
      permissaoAcesso: menuAcesso
    })

    this.createMenu()

    await VersaoService.sincronizarVersao()
  }

  onWrapperClick () {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }

    this.menuClick = false
  }

  onToggleMenu (event) {
    this.menuClick = true
    if (this.isDesktop()) {
      this.setState({
        staticMenuInactive: !this.state.staticMenuInactive
      })
    } else {
      const mobileMenuActive = this.state.mobileMenuActive
      this.setState({
        mobileMenuActive: !mobileMenuActive
      })
    }
    event.preventDefault()
  }

  onSidebarClick (event) {
    this.menuClick = true
  }

  onMenuItemClick (event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }
  }

  getPermissaoAcesso (codigoMenu) {
    const menu = this.state.permissaoAcesso.find(
      (menu) => codigoMenu === menu.codmen_mag
    )

    // Se possui acesso ao menu informado
    if (menu) {
      return true
    } else {
      return false
    }
  }

  createMenu () {
    this.menu = [{
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      to: '/home',
      visible: true
    }]

    let itemsCadastros = []
    // Cadastro de empresa
    if (this.getPermissaoAcesso(1)) {
      itemsCadastros.push({
        label: 'Empresa',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/empresas'
      })
    }
    if (this.getPermissaoAcesso(1)) {
      itemsCadastros.push({
        label: 'Chave Acesso',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/chaveacesso'
      })
    }
    if (this.getPermissaoAcesso(2)) {
      itemsCadastros.push({
        label: 'Grupo de Usuário',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/gruposusuarios'
      })
    }
    if (this.getPermissaoAcesso(3)) {
      itemsCadastros.push({
        label: 'Permissão',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/permissoes'
      })
    }
    if (this.getPermissaoAcesso(4)) {
      itemsCadastros.push({
        label: 'Tipos de Acesso',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/tiposacessos'
      })
    }
    if (this.getPermissaoAcesso(5)) {
      itemsCadastros.push({
        label: 'Usuário',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/usuarios'
      })
    }
    if (this.getPermissaoAcesso(6)) {
      itemsCadastros.push({
        label: 'Versão',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/versoes'
      })
    }
    this.menu.push({
      label: 'Cadastros',
      icon: 'pi pi-fw',
      reactIcon: BsFillFilePersonFill,
      items: itemsCadastros
    })

    let itemsAtualizacao = []

    if (this.getPermissaoAcesso(8)) {
      itemsAtualizacao.push({
        label: 'Painel de atualização',
        icon: 'pi pi-fw',
        reactIcon: VscCircleFilled,
        to: '/atualizacao'
      })
    }
    this.menu.push({
      label: 'Atualização',
      icon: 'pi pi-fw',
      reactIcon: BsTools,
      items: itemsAtualizacao
    })
    let itemsIntegracao = []
    itemsIntegracao.push({
      label: 'Configuração',
      icon: 'pi pi-fw',
      reactIcon: VscCircleFilled,
      to: '/integracao'
    })
    this.menu.push({
      label: 'Integrações',
      icon: 'pi pi-fw',
      reactIcon: BsFillGearFill,
      items: itemsIntegracao
    })
  }

  addClass (element, className) {
    if (element.classList)
      element.classList.add(className)
    else
      element.className += ' ' + className
  }

  removeClass (element, className) {
    if (element.classList)
      element.classList.remove(className)
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }

  isDesktop () {
    return window.innerWidth > 1024
  }

  componentDidUpdate () {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden')
    else
      this.removeClass(document.body, 'body-overflow-hidden')
  }

  render () {
    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive
    })

    const usuario = getStore().usuarioLogado

    return (
      <>
        {!(usuario.codigo_usu) && (
          <div className="loading-container">
            <ProgressSpinner />
          </div>
        )}
        {(usuario.codigo_usu) && (
          <div className={wrapperClass} onClick={this.onWrapperClick}>
            <AppTopbar onToggleMenu={this.onToggleMenu} {...this.props}/>

            <div className="layout-sidebar layout-sidebar-light" onClick={this.onSidebarClick}>
              <div className="layout-logo">
                <object data="/assets/layout/images/logo_sances.png"></object>
              </div>
              <AppProfile {...this.props} />
              <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} {...this.props} />
            </div>
            <div className="layout-main">
              <div className="layout-content">
                {this.props.children}
              </div>
            </div>
            <div className="layout-mask"></div>
            <Message />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({ loading: state.loading })

export default connect(mapStateToProps)(AppContent)
