import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '../../../components/layout/Container'
import { baseForm } from '../../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import EmpresaService from '../../../services/cadastro/EmpresaService'
import DadosAcessoEmpresa from './DadosAcessoEmpresa'
import DadosPrincipaisEmp from './DadosPrincipaisEmp'
import { EmpresaValidator } from './EmpresaValidator'
import TabMenuContainer from '../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../components/layout/TabMenuContainer/TabMenuPage'
import DadosFiliais from './DadosFiliais'
import FilialService from '../../../services/cadastro/FilialService'

const EmpresaForm = ({ isNew, model }) => {
  const [isNovo, setIsNovo] = useState(isNew)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const history = useHistory()
  useEffect(async () => {
    if (isNovo) setEdicaoHabilitada(true)
    const filial = new FilialService()
    let dataFilial = await filial.get(form.values.codigo_emp)
    form.setFieldValue('filial', dataFilial)
  }, [])

  const handleSubmit = async (data) => {
    try {
      const empresa = await EmpresaService.save(data)

      history.push('/empresas/')
      form.setFieldValue('codigo_emp', empresa.codigo_emp)

      setIsNovo(false)
      showSuccessMessage('Registro salvo com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao salvar registro')
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()
    const errors = await form.validateForm(form.values)
    if (Object.keys(errors).length !== 0) {
      form.handleSubmit(e)
      showWarnMessage('Campos obrigatórios não preenchidos!')
    } else {
      form.handleSubmit(e)
    }
  }

  const form = baseForm({
    initialValues: model,
    validationSchema: EmpresaValidator,
    onSubmit: handleSubmit
  })

  return (

    <Container col="12">
      <form onSubmit={submitForm}>
        <div className="flex justify-content-between form-header">
          <h1>Empresa</h1>
          {!isNovo && (
            <div className="flex justify-content-between">
              <Button
                type="button"
                label="Editar"
                className="edit-button"
                icon="pi pi-pencil"
                onClick={() => setEdicaoHabilitada(!edicaoHabilitada)}
              />
            </div>
          )}
        </div>
        <div className="grid form-body">
          <TabMenuContainer>
            <TabMenuPage header="INFORMAÇÕES PRINCIPAIS">
              <DadosPrincipaisEmp
                form={form}
                edicaoHabilitada={edicaoHabilitada}
              />
            </TabMenuPage>
            <TabMenuPage header="ACESSOS REMOTO">
              <DadosAcessoEmpresa
                form={form}
                edicaoHabilitada={edicaoHabilitada}
              />
            </TabMenuPage>
            <TabMenuPage header="FILIAIS">
              <DadosFiliais form={form}
                edicaoHabilitada={edicaoHabilitada} />
            </TabMenuPage>

          </TabMenuContainer>

        </div>
        <div className="flex justify-content-end form-footer">
          {edicaoHabilitada && (
            <Button
              type="submit"
              label="Salvar"
              className="p-button"
            />
          )}
        </div>
      </form>
    </Container>
  )
}

export default EmpresaForm
