export const getGrupoUsuarioDTO = (data = {}) => {
  return {

    codigo_gru: data.codigo_gru || null,
    nome_gru: data.nome_gru || null,
    datins_gru: data.datins_gru || null,
    datalt_gru: data.datalt_gru || null,
    ativo_gru: data.ativo_gru === 1 ? true : false,
    codusuins_gru: data.codusuins_gru || null,
    codusualt_gru: data.codusualt_gru || null,
    usuarioAlteracao: data.usuarioAlteracao || null,
    usuarioInsercao: data.usuarioInsercao || null,
    acessoMenuGrupo: data.acessoMenuGrupo || []
  }
}

export const postGrupoUsuarioDTO = (data = {}) => {
  return {
    codigo_gru: data.codigo_gru || null,
    nome_gru: data.nome_gru || null,
    datins_gru: data.datins_gru || null,
    datalt_gru: data.datalt_gru || null,
    ativo_gru: data.ativo_gru ? 1 : 0,
    codusuins_gru: data.codusuins_gru || null,
    codusualt_gru: data.codusualt_gru || null,
    usuarioAlteracao: data.usuarioAlteracao || null,
    usuarioInsercao: data.usuarioInsercao || null,
    acessoMenuGrupo: data.acessoMenuGrupo || []
  }
}
