import React from 'react'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Column } from 'primereact/column'
import List from '../../../classes/List'
import CommonHelper from '../../../helpers/CommonHelper'
import DataList from '../../../components/utils/DataList'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import TipoAcessoService from '../../../services/cadastro/TipoAcessoService'
import { getTipoAcessoDTO } from '../../../dtos/cadastro/TipoAcessoDTO'

export class TipoAcessoList extends List {
  constructor (props) {
    super(
      props,
      getTipoAcessoDTO,
      TipoAcessoService,
      'tipoacesso',
      'codigo_tia',
      'ativos'
    )

    this.state = {
      ...this.state
    }

    this.setColumns([
      {
        key: 'codigo_tia',
        component: (
          <Column
            className="p-col-1"
            field="codigo_tia"
            header="Código"
            sortable
            headerClassName="codigo_tia" />
        )
      },
      {
        key: 'nome_tia',
        component: (
          <Column
            className="p-col-4"
            field="nome_tia"
            header="Nome"
            sortable
            headerClassName="nome_tia" />
        )
      },
      {
        key: 'datins_tia',
        component: (
          <Column
            className="p-col-2"
            field="datins_tia"
            body={(data) => CommonHelper.dateTimeToBrazilian(data.datins_tia)}
            header="Inserido em"
            sortable
            headerClassName="datins_tia" />
        )
      },
      {
        key: 'usuarioInsercao',
        component: (
          <Column
            className="p-col-3"
            field="usuarioInsercao"
            header="Inserido por"
            sortable
            headerClassName="usuarioInsercao" />
        )
      },
      {
        key: 'datalt_tia',
        component: (
          <Column
            className="p-col-2"
            field="datalt_tia"
            body={(data) => CommonHelper.dateTimeToBrazilian(data.datalt_tia)}
            header="Alterado em"
            sortable
            headerClassName="datalt_tia" />
        )
      },
      {
        key: 'usuarioAlteracao',
        component: (
          <Column
            className="p-col-3"
            field="usuarioAlteracao"
            header="Alterado por"
            sortable
            headerClassName="usuarioAlteracao" />
        )
      },
      {
        key: 'ativo_tia',
        component: (
          <Column
            className="p-col-1"
            field="ativo_tia"
            body={(data) => data.ativo_tia === 1 ? 'Sim' : 'Não'}
            header="Ativo" />
        )
      }
    ])
  }

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'ativos':
        filter.ativo_tia = 'ativo'
        break
      case 'inativos':
        filter.ativo_tia = 'inativo'
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Tipos de acesso</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'ativos' }, 'filter-option')}
              onClick={() => onSelectOption('ativos')}>
              <span className="option-label">Ativos</span>
              <div className="option-quantity">
                {this.state.quantidades.quantidadeAtivos}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'inativos' }, 'filter-option')}
              onClick={() => onSelectOption('inativos')}>
              <span className="option-label">Inativos</span>
              <div className="option-quantity">
                {this.state.quantidades.quantidadeInativos}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            onRowSelect={(e) => this.onView(e.data)}
            responsive
            showFilter
            filterPlaceholder="Procurar tipo de acessos"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}
