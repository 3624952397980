import { Column } from 'primereact/column'
import { Skeleton } from 'primereact/skeleton'
import React from 'react'
import List from '../../../classes/List'
import SearchInput from '../../../components/inputs/SearchInput'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage } from '../../../components/utils/Message'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { getConfigDTO } from '../../../dtos/configuracao/ConfigDTO'
import ConfigService from '../../../services/configuracao/ConfigService'
import ConfiguracaoModal from './ConfiguracaoModal'
export default class ConfiguracaoPorConf extends List {
  constructor (props) {
    super(
      props,
      getConfigDTO,
      ConfigService,
      'config',
      'numeroCon',
      'codigo_con'
    )

    this.state = {
      ...this.state,
      configuracao: [],
      sugestaoConfig: '',
      configInicial: [],
      listConfigEmpresas: [],
      visibleModal: false,
      configSelecionada: [],
      loading: true

    }

    this.setColumns([
      {
        key: 'numero_con',
        component: (
          <Column
            key={'numero_con'}
            className="p-col-1"
            field="numero_con"
            header="Número"
            sortable
            headerClassName="numero_con" />
        )
      },
      {
        key: 'descri_con',
        component: (
          <Column
            key={'descri_con'}
            className="p-col-4"
            field="descri_con"
            header="Descrição"
            sortable
            headerClassName="descri_con" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            key={'valor_con'}
            className="p-col-4"
            field="valor_con"
            header="Valor"
            body={(data) => <ResponsiveColumn
              column="nomraz_emp"
              value={this.handleValorCon(data.valor_con)}
            />}
            sortable
            headerClassName="valor_con" />
        )
      },
      {
        key: 'nomfan_fil',
        component: (
          <Column
            key={'nomfan_fil'}
            className="p-col-1"
            field="nomfan_fil"
            body={(data) => <ResponsiveColumn
              column="nomfan_fil"
              value={data?.filial?.apelid_fil || data?.filial?.nomfan_fil || 'Multi-Empresa'}
            />}
            header="Filial"
            sortable
            headerClassName="nomfan_fil" />
        )
      },
      {
        key: 'nomraz_emp',
        component: (
          <Column
            key={'nomraz_emp'}
            className="p-col-1"
            field="nomraz_emp"
            body={(data) => <ResponsiveColumn
              column="nomraz_emp"
              value={data.empresa?.nomfan_emp || data.empresa?.nomraz_emp || ''}
            />}
            header="Empresa"
            sortable
            headerClassName="nomraz_emp" />
        )
      }

    ])
  }

  handleValorCon = (valor) => {
    if (String(valor).toLocaleUpperCase() === '.T.') {
      valor = 'Sim'
    } else if (String(valor).toLocaleUpperCase() === '.F.') {
      valor = 'Não'
    } else {
      valor = valor
    }
    return valor
  }

  handleConfig = async (e) => {
    const config = e.value
    this.setState({ configuracao: config })
    this.sugerirConfig(config)
    if (config === '') {
      this.getConfig('')
    }
  }

   setConfig = async (e) => {
     let config = e.value
     this.setState({ configuracao: config })
     this.getConfig(config?.numero_con)
   }

  setRetorno = (response) => {
    this.setState({
      list: response.data,
      totalRecords: response.meta?.total,
      totalPages: response.meta?.last_page,
      quantidades: response.quantidades
    })
    this.setState({ loading: false })
  }

  getConfig = async (numeroCon) => {
    try {
      let config = new ConfigService()
      let response
      if (typeof this.state.filter === 'function') {
        response = await config.filter(`numeroConfiguracao=${numeroCon}`, this.state.page, 10)
      } else {
        response = await config.filter(`descricaoFiltro=${this.state.filter}&numeroConfiguracao=${numeroCon}`, this.state.page, this.state.rows)
      }
      this.setRetorno(response)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os registros!')
    }
  }

  componentDidMount () {
    this.setRetorno(this.props.listConfig)
  }

  sugerirConfig = async () => {
    try {
      const configuracao = new ConfigService()
      const configSugerida = (
        await configuracao.filter(`numeroConfigSugerida=${this.state.configuracao}`, this.state.page, 10)
      ).data
      this.setState({ sugestaoConfig: configSugerida })
    } catch (error) {
      showErrorMessage(
        error.message || 'Ocorreu um erro inesperado ao sugerir uma configuração!'
      )
    }
  }

  onPageChange = async data => {
    const first = data.first
    const page = data.page + 1
    await this.setState({ first, page })
    if (this.state.configuracao?.numero_con) {
      this.getConfig(this.state.configuracao?.numero_con)
    } else {
      this.onFilter()
    }
  }

  handleChangeFilter = event => {
    let filter = this.state.filter
    filter = event.target.value
    this.setState({ filter })
  }

  toFilter () {
    let filtro

    if (this.state.configuracao?.numero_con) {
      filtro = `descricaoFiltro=${this.state.filter}&numeroConfiguracao=${this.state.configuracao?.numero_con}`
    } else {
      if (typeof this.state.filter !== 'function') {
        filtro = `descricaoFiltro=${this.state.filter}`
      } else {
        filtro = ''
      }
    }
    return filtro
  }

  onFilter = async () => {
    try {
      this.setState({ loading: true })
      let config = new ConfigService()

      let response = await config.filter(this.toFilter(), this.state.page, this.state.rows)
      this.setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  loadingTemplate = (options) => {
    return (
      <div
        className="flex align-items-center"
        style={{ height: '17px', flexGrow: '1', overflow: 'hidden' }}
      >
        <Skeleton
          width={
            options.cellEven
              ? options.field === 'year'
                ? '30%'
                : '40%'
              : '60%'
          }
          height="1rem"
        />
      </div>
    )
  };

  render () {
    const { rows, totalRecords, first, page, list, selected, configuracao, sugestaoConfig, loading } = this.state
    const configSelecionada = selected || []
    return (
      <Page>
        <div className="flex align-items-center page-header">
          <div className={'mx-3 w-full'} >
            <SearchInput
              label='Configuração'
              AutoComplete
              field='descri_con'
              value={configuracao || configuracao?.descri_con }
              suggestions={sugestaoConfig}
              completeMethod={this.sugerirConfig}
              onChange={this.handleConfig}
              onSelect={(e) => this.setConfig(e)}
              filterName='numeroCon'
              className={'w-full'}
              FiltroModal
              filtroTitle='Pesquisar Configuração'
              service={ConfigService}
              model={getConfigDTO}
              columns={[
                { campo: 'numero_con', nome: 'Número' },
                { campo: 'descri_con', nome: 'Descrição' }
              ]}
            />
          </div>
        </div>
        <Container>
          <DataList
            loading={loading}
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            onRowSelect={() => this.setState({ visibleModal: true })}
            showFilter
            filterPlaceholder="Procurar configuração"
            filterName="numeroConfiguracao"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}

          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <ConfiguracaoModal
          visible={this.state.visibleModal}
          onHide={() => {
            this.setState({ configSelecionada: [] })
            this.setState({ visibleModal: false })
            this.setState({ selected: false })
          }}
          configSelecionada={configSelecionada}
        />
      </Page>
    )
  }
}
