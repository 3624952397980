export const getChaveAcessoDTO = (data = {}) => {
  return {
    chave_lgr: data.chave_lgr || null,
    nomraz_pes: data.nomraz_pes || null,
    nomfan_pej: data.nomfan_pej || null,
    cgccpf_pes: data.cgccpf_pes || null,
    lic_mes: data.lic_mes || null,
    lic_ano: data.lic_ano
  }
}
