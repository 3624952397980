import React from 'react'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Column } from 'primereact/column'
import List from '../../../classes/List'
import CommonHelper from '../../../helpers/CommonHelper'
import DataList from '../../../components/utils/DataList'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import UsuarioService from '../../../services/cadastro/UsuarioService'
import { getUsuarioDTO } from '../../../dtos/cadastro/UsuarioDTO'

export class UsuarioList extends List {
  constructor (props) {
    super(
      props,
      getUsuarioDTO,
      UsuarioService,
      'usuario',
      'codigo_usu',
      'ativos'
    )

    this.state = {
      ...this.state
    }

    this.setColumns([
      {
        key: 'codigo_usu',
        component: (
          <Column
            className="p-col-1"
            field="codigo_usu"
            header="Código"
            sortable
            headerClassName="codigo_usu" />
        )
      },
      {
        key: 'nome_usu',
        component: (
          <Column
            className="p-col-4"
            field="nome_usu"
            header="Nome"
            sortable
            headerClassName="nome_usu" />
        )
      },
      {
        key: 'cpf_usu',
        component: (
          <Column
            className="p-col-2"
            field="cpf_usu"
            body={(data) => CommonHelper.formatCpf(data.cpf_usu)}
            header="CPF"
            sortable
            headerClassName="cpf_usu" />
        )
      },
      {
        key: 'email_usu',
        component: (
          <Column
            className="p-col-3"
            field="email_usu"
            header="E-mail"
            sortable
            headerClassName="email_usu" />
        )
      },
      {
        key: 'ativo_usu',
        component: (
          <Column
            className="p-col-1"
            field="ativo_usu"
            body={(data) => data.ativo_usu === 1 ? 'Sim' : 'Não'}
            header="Ativo" />
        )
      },
      {
        key: 'grupousuario',
        component: (
          <Column
            className="p-col-3"
            field="grupousuario"
            header="Grupo"
            sortable
            headerClassName="grupousuario" />
        )
      }
    ])
  }

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'ativos':
        filter.ativo_usu = 'ativo'
        break
      case 'inativos':
        filter.ativo_usu = 'inativo'
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Usuários</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'ativos' }, 'filter-option')}
              onClick={() => onSelectOption('ativos')}>
              <span className="option-label">Ativos</span>
              <div className="option-quantity">
                {this.state.quantidades.quantidadeAtivos}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'inativos' }, 'filter-option')}
              onClick={() => onSelectOption('inativos')}>
              <span className="option-label">Inativos</span>
              <div className="option-quantity">
                {this.state.quantidades.quantidadeInativos}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            onRowSelect={(e) => this.onView(e.data)}
            responsive
            showFilter
            filterPlaceholder="Procurar usuários"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}
