const handleValorCon = (data, cortarString) => {
  if (String(data).toLocaleUpperCase() === '.T.') {
    data = 'Sim'
  } else if (String(data).toLocaleUpperCase() === '.F.') {
    data = 'Não'
  } else if (typeof data === 'string' && cortarString) {
    data = data.slice(0, 20)
  } else {
    data = data
  }
  return data
}

export const getConfigDTO = (data = {}, cortarString = true) => {
  let config = data

  if (!Array.isArray(config)) {
    return {
      codigo_con: config.codigo_con || null,
      codemp_con: config.codemp_con || null,
      numero_con: config.numero_con || null,
      grupo_con: config.grupo_con || null,
      tipo_con: config.tipo_con || null,
      variav_con: config.variav_con || null,
      valor_con: handleValorCon(config.valor_con, cortarString),
      docume_con: config.docume_con || null,
      vis_con: config.vis_con || null,
      descri_con: config.descri_con || null,
      nomfan_fil: config?.filial?.nomraz_fil || null,
      apelid_fil: config?.filial?.apelid_fil || 'Multiempresa'
    }
  }
  const result = config.map((config) => {
    return {
      codigo_con: config.codigo_con || null,
      codemp_con: config.codemp_con || null,
      numero_con: config.numero_con || null,
      grupo_con: config.grupo_con || null,
      tipo_con: config.tipo_con || null,
      variav_con: config.variav_con || null,
      valor_con: handleValorCon(config.valor_con, cortarString),
      docume_con: config.docume_con || null,
      vis_con: config.vis_con || null,
      descri_con: config.descri_con || null,
      nomfan_fil: config?.filial?.nomraz_fil || null,
      apelid_fil: config?.filial?.apelid_fil || 'Multiempresa'
    }
  })
  return result
}
