import { postTipoAcessoDTO } from '../../dtos/cadastro/TipoAcessoDTO'
import ServiceBase from '../base/ServiceBase'

export default class TipoAcessoService {
  static async get (id) {
    let resultado = await ServiceBase.getBase(`tipoacesso/${id}`)

    return resultado
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`tiposacessos?page=${page}&perPage=${perPage}`)
  }

  static async getTodos () {
    const res = await ServiceBase.getBase('tiposacessos/todos')
    return res
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`tiposacessos?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('tipoacesso', postTipoAcessoDTO(data))
  }
}
