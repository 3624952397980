export const getEmpresaDTO = (data = {}) => {
  console.log(data)
  return {
    codigo_emp: data.codigo_emp || null,
    nomraz_emp: data.nomraz_emp || null,
    nomfan_emp: data.nomfan_emp || null,
    ativo_emp:
      data.ativo_emp !== undefined
        ? data.ativo_emp === 1
          ? true
          : false
        : true,
    cnpj_emp: data.cnpj_emp || null,
    datins_emp: data.datins_emp || null,
    datult_emp: data.datult_emp || null,
    codusuins_emp: data.codusuins_emp || null,
    codusualt_emp: data.codusualt_emp || null,
    senhabanco_emp: data.senhabanco_emp || null,
    ipbanco_emp: data.ipbanco_emp || null,
    obs_emp: data.obs_emp || null,
    codver_emp: data.codver_emp || null,
    acessosemp: data.acessosemp || [],
    versao: data.versao || []
  }
}

export const postEmpresaDTO = (data = {}) => {
  return {
    codigo_emp: data.codigo_emp || null,
    nomraz_emp: data.nomraz_emp || null,
    nomfan_emp: data.nomfan_emp || null,
    ativo_emp: data.ativo_emp ? 1 : 0,
    cnpj_emp: data.cnpj_emp || null,
    datins_emp: data.datins_emp || null,
    datult_emp: data.datult_emp || null,
    codusuins_emp: data.codusuins_emp || null,
    codusualt_emp: data.codusualt_emp || null,
    senhabanco_emp: data.senhabanco_emp || null,
    ipbanco_emp: data.ipbanco_emp || null,
    obs_emp: data.obs_emp || null,
    acessosemp: data.acessosemp || null,
    codver_emp: data.versao.codigo_ver || null
  }
}
