export const getUsuarioDTO = (data = {}) => {
  return {
    codigo_usu: data.codigo_usu || null,
    nome_usu: data.nome_usu || null,
    email: data.email || null,
    password: data.password || null,
    cpf_usu: data.cpf_usu || null,
    ativo_usu: data.ativo_usu ? 1 : 0,
    datins_usu: data.datins_usu || null,
    datalt_usu: data.datalt_usu || null,
    codgru_usu: data.codgru_usu || null
  }
}

export const postUsuarioDTO = (data = {}) => {
  return {
    codigo_usu: data.codigo_usu || null,
    nome_usu: data.nome_usu || null,
    email: data.email || null,
    password: data.password || null,
    cpf_usu: data.cpf_usu || null,
    ativo_usu: data.ativo_usu ? 1 : 0,
    datins_usu: data.datins_usu || null,
    datalt_usu: data.datalt_usu || null,
    codgru_usu: data.codgru_usu || null
  }
}
