import { Column } from 'primereact/column'
import React from 'react'
import List from '../../classes/List'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import ResponsiveColumn from '../../components/utils/ResponsiveColumn'
import { getVersaoDTO } from '../../dtos/cadastro/VersaoDTO'
import { formatDate } from '../../helpers/formaters'
import VersaoEmpresa from '../../services/cadastro/VersaoEmpresa'
export default class VersoesList extends List {
  constructor (props) {
    super(
      props,
      getVersaoDTO,
      VersaoEmpresa,
      'versao',
      'codigo_ver',
      'liberada_ver'
    )
    this.state = {
      ...this.state,
      colorVersion: 'p-col-2'
    }
    this.setColumns([
      {
        key: 'codigo_ver',
        component: (
          <Column
            className="p-col-1"
            field="codigo_ver"
            header="Código"
            sortable
            headerClassName="codigo_ver" />
        )
      },
      {
        key: 'nome_ver',
        component: (
          <Column
            className="p-col-4"
            field="nome_ver"
            header="Nome Versão"
            sortable
            headerClassName="nome_ver" />
        )
      },
      {
        key: 'descri_ver',
        component: (
          <Column
            className="p-col-2"
            field="descri_ver"
            body={(data) => data.descri_ver}
            header="Descrição"
            sortable
            headerClassName="descri_ver" />
        )
      },
      {
        key: 'datlib_ver',
        component: (
          <Column
            className="p-col-2"
            field="datlib_ver"
            body={data => <ResponsiveColumn
              column="acabado_vei"
              value={data.liberada_ver === 0 ? 'Não liberada' : 'Liberada'}
              className={data.liberada_ver === 0 ? 'colored-label orange' : 'colored-label blue'}
            />}
            header="Versão Liberada"
            sortable
            headerClassName="liberada_ver" />
        )
      },
      {
        key: 'datlib_ver',
        component: (
          <Column
            className="p-col-2"
            field="datlib_ver"
            body={(data) => data.datlib_ver === null ? 'Não informada' : formatDate(data.datlib_ver)
            }
            header="Data da Versão"
            sortable
            headerClassName="datlib_ver" />
        )
      }
    ])
  }

  render () {
    const { rows, totalRecords, first, page, list } = this.state
    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Versão</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter
            filterPlaceholder="Procurar Versão"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}
