import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import React, { useState, useEffect } from 'react'
import { Fieldset } from 'primereact/fieldset'
// import CheckBoxInput from '../../../components/inputs/CheckBoxInput'

import TextInput from '../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../helpers/useValidateInput'
import MenuService from '../../../services/cadastro/MenuService'
import MenuAcessoGrupoService from '../../../services/cadastro/MenuAcessoGrupoService'

const DadosPrincipaisGruUsu = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)

  const [menus, setMenus] = useState([])
  useEffect(async () => {
    let listaMenu = await MenuService.getTodos()
    setMenus(listaMenu)

    let acessosMenu = await MenuAcessoGrupoService.getMenuAcessoByGrupo(form.values.codigo_gru)

    let arrayMenu = new Array()
    acessosMenu.forEach(element => {
      arrayMenu.push(element.codmen_mag)
    })
    setCheckedState(arrayMenu)
    form.setFieldValue('acessoMenuGrupo', arrayMenu)
  }, [])

  const [checkedState, setCheckedState] = useState([])

  const handleOnChange = (e) => {
    let selectedCheckedState = [...checkedState]

    if (e.checked)
      selectedCheckedState.push(e.value)
    else
      selectedCheckedState.splice(selectedCheckedState.indexOf(e.value), 1)

    setCheckedState(selectedCheckedState)
    form.setFieldValue('acessoMenuGrupo', selectedCheckedState)
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-9">
        <RequiredLabel label="Nome" valid={isFormFieldValid('nome_gru')}/>
        <TextInput
          value={form.values.nome_gru}
          name="nome_gru"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nome_gru') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome do grupo"
        />
        <FieldErrorMessage message={getFormErrorMessage('nome_gru')}/>
      </div>
      <div className="field checkbox-container col-1">
        <Checkbox
          name="ativo_gru"
          value={form.values.ativo_gru}
          onChange={form.handleChange}
          checked={form.values.ativo_gru}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-2">Ativo</label>
      </div>

      <Fieldset legend={<b>Acessos de Menu</b>} className="col-12" style={{ marginBottom: '20px', marginTop: '10px', minWidth: '300px' }}>

        <ul className='no-bullets'>

          {menus.map(({ codigo_men, descri_men }, index) => {
            return (
              <li key={codigo_men}>

                <div className="field checkbox-container col-12">
                  <Checkbox
                    value={codigo_men}
                    checked={checkedState.includes(codigo_men)}
                    onChange={handleOnChange}
                    disabled={!edicaoHabilitada}
                    id={`custom-checkbox-${codigo_men}`}
                    name={`custom-checkbox-${codigo_men}`}
                  />
                  <label htmlFor={`custom-checkbox-${codigo_men}`} className="p-checkbox-label mb-0 pl-2">{codigo_men} - {descri_men}</label>
                </div>
              </li>
            )
          })}
        </ul>
      </Fieldset>
    </div>

  )
}

export default DadosPrincipaisGruUsu
