import { Checkbox } from 'primereact/checkbox'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useEffect, useState } from 'react'
import TextAreaInput from '../../../components/inputs/TextAreaInput'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import { getConfigDTO } from '../../../dtos/configuracao/ConfigDTO'
import { createColumns } from '../../../helpers/tableConfigs'
import ConfigService from '../../../services/configuracao/ConfigService'

function ConfiguracaoModal ({ onHide, visible, onlyVisualizacao, edicaoHabilitada, configSelecionada, page, rows }) {
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [ativa, setAtiva] = useState(false)
  const columns = createColumns([{
    field: 'apelid_fil',
    header: 'Filial',
    alignText: 'text-left',
    headerClassName: 'text-left',
    sortable: false
  },
  {
    field: 'valor_con',
    header: 'Valor',
    sortable: false
  }
  ])

  useEffect(() => {
    getConfigSelecionada()
  }, [configSelecionada])

  const getConfigSelecionada = async () => {
    if (configSelecionada?.numero_con) {
      await getConfig(configSelecionada?.numero_con, configSelecionada?.codemp_con)
      setAtiva(configSelecionada?.ativo_con === 1)
    }
  }

  const getConfig = async (numeroCon, codemp) => {
    try {
      setIsLoading(true)
      if (typeof numeroCon === 'number') {
        const configuracao = new ConfigService()
        let response = await configuracao.getConfigEmpresa(numeroCon, codemp)
        setList(getConfigDTO(response, false))
      }
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os registros!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      onHide={onHide}
      header={`Configuração - ${configSelecionada?.numero_con}`}
      visible={visible}
      width="70"
      btnSalvar={edicaoHabilitada && !onlyVisualizacao ? form.submitForm : undefined}
      modal
    ><form>
        {isLoading ? <div style={{ textAlign: 'center' }}>
          <ProgressSpinner />
        </div>
          : <div>
            <div className='flex '>
              <div className="field checkbox-container col-8">
                <span className="font-semibold">{`${configSelecionada?.numero_con} - ${configSelecionada?.descri_con}`}</span>
              </div>
              <div className="field checkbox-container col-1">
                <Checkbox
                  name="ativo_con"
                  value={configSelecionada?.ativo_con}
                  checked={ativa}
                  disabled={true}
                />
                <label className="p-checkbox-label mb-0 pl-2">Ativo</label>
              </div>

              <div className="field checkbox-container col-1">
                <Checkbox
                  name="vis_con"
                  value={configSelecionada?.vis_con}
                  checked={configSelecionada?.vis_con === 1}
                  disabled={true}
                />
                <label className="p-checkbox-label mb-0 pl-2">Visível</label>
              </div>
              <div className="field checkbox-container col-1">
                <Checkbox
                  name="codfil_con"
                  value={configSelecionada?.codfil_con}
                  checked={configSelecionada?.codfil_con === null}
                  disabled={true}
                />
                <label className="p-checkbox-label mb-0 pl-2">Multiempresa</label>
              </div>
            </div>
            <div className='field col-12 md:col-12'>
              <TextAreaInput
                label='Documentação'
                value={configSelecionada?.docume_con}
                disabled={true}
                name='docume_con'
                className={'inputfield w-full'}
                placeholder='Documentação'
              />
            </div>
            <DataList
              data={list}
            >
              {columns.map((column) => column.component)}
            </DataList>
          </div>
        }
      </form>
    </Modal>
  )
}

export default ConfiguracaoModal
