import ServiceBase from '../base/ServiceBase'

export default class FilialService {
  async get (id) {
    let retorno = await ServiceBase.getBase(`filial/${id}`)
    return retorno
  }

  async filter (query, page, perPage) {
    let dados = await ServiceBase.getBase(`filial?${query}&page=${page}&perPage=${perPage}`)
    return dados
  }

  static async atualizarFilial (xml) {
    let texto = await ServiceBase.postBase('filial/atualizar', xml)
    return texto
  }
}
