import { Column } from 'primereact/column'
import React from 'react'
import List from '../../classes/List'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import ResponsiveColumn from '../../components/utils/ResponsiveColumn'
import { getChaveAcessoDTO } from '../../dtos/cadastro/ChaveAcessoDTO'
import CommonHelper from '../../helpers/CommonHelper'
import ChaveAcessoSerivice from '../../services/cadastro/ChaveAcessoService'
export default class ChaveAcessoList extends List {
  constructor (props) {
    super(
      props,
      getChaveAcessoDTO,
      ChaveAcessoSerivice,
      'chaveacesso',
      'cgccpf_emp',
      'ativas'
    )

    this.state = {
      ...this.state
    }
    this.setColumns([
      {
        key: 'nomfan_pej',
        component: (
          <Column
            key={'nomfan_pej'}
            className="p-col-4"
            field="nomfan_pej"
            header="Nome Fantasia"
            sortable
            headerClassName="nomfan_pej" />
        )
      },
      {
        key: 'nomraz_pes',
        component: (
          <Column
            key={'nomraz_pes'}
            className="p-col-4"
            field="nomraz_pes"
            header="Nome Razão"
            sortable
            headerClassName="nomraz_pes" />
        )
      },
      {
        key: 'cgccpf_pes',
        component: (
          <Column
            key={'cgccpf_pes'}
            className="p-col-4"
            field="cgccpf_pes"
            body={(data) => CommonHelper.formatCnpj(data.cgccpf_pes)}
            header="CNPJ"
            sortable
            headerClassName="cgccpf_pes" />
        )
      },
      {

        key: 'chave_lgr',
        component: (
          <Column
            key={'chave_lgr'}
            className="p-col-4"
            field="chave_lgr"
            body={(data) => `${data.mes_lgr}-${data.ano_lgr}` === CommonHelper.nextMonthToYear() ? data.cgccpf_pes === '00000000000000' ? <ResponsiveColumn
              column="chave_lgr"
              value={'Consulte o supervisor'}
              className={'colored-label orange'}
            /> : data.chave_lgr : <ResponsiveColumn
              column="chave_lgr"
              value={'Consulte o financeiro'}
              className={'colored-label orange'}
            /> }
            header="Chave Acesso"
            sortable
            headerClassName="chave_lgr" />
        )
      }
    ])
  }

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state
    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Chave de Acesso</h4>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            showFilter
            filterPlaceholder="Procurar empresas"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}
