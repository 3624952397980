import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SplitButton } from 'primereact/splitbutton'
import { logout } from '../../helpers/auth'
import { getStore } from '../../redux/store'
import ChangePasswordModal from '../utils/ChangePasswordModal'

export class AppTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null
  }

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      busca: '',
      redirect: false,
      selectedCity: null,
      selectedFile: null,
      required: false,
      visibleChangePassword: false
    }
  }

  logout = () => {
    logout()
    window.location.href = '/login'
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onCloseDialog = async () => {
    this.setState({
      required: false,
      visibleChangePassword: false
    })
  }

  getActions () {
    const actions = [{
      label: 'Trocar Senha',
      icon: 'pi pi-fw pi-unlock',
      command: () => {
        this.setState({ visibleChangePassword: true })
      }
    }]

    actions.push({
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => {
        this.logout()
      }
    })

    return actions
  }

  render () {
    const usuario = getStore().usuarioLogado

    return (
      <>
        <div className="layout-topbar clearfix">
          <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
            <span className="pi pi-bars" />
          </button>
          <div className="layout-topbar-menu">
            <span className="small-user-menu">
              <SplitButton label={' '} model={this.getActions()} className="p-mr-2 user-menu" />
            </span>
            <span className="big-user-menu">
              <SplitButton label={usuario.nome_usu} model={this.getActions()} className="p-mr-2 user-menu" />
            </span>
          </div>
        </div>
        <ChangePasswordModal
          visible={this.state.visibleChangePassword}
          usuarioLogado={usuario}
          hide={this.onCloseDialog}
          reloadUsuario={this.getUsuarioLogado}
        />
      </>
    )
  }
}
