import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown'

class SelectInput extends Component {
  className () {
    let name = this.props.value ? 'p-inputwrapper-filled' : ''
    name = name.concat(' ', this.props.required && !this.props.value ? 'required' : '')
    return name
  }

  render () {
    if (this.props.noFloatLabel) {
      return (
        <span className="selectinput">
          <label
            className={this.props.required && !this.props.value ? 'label' : ''}
            htmlFor={this.props.id}
          >
            {this.props.label}
          </label>
          <Dropdown
            className={this.className()} {...this.props}
            value={this.props.value || ''}
            optionLabel={this.props.optionLabel}
            optionValue={this.props.optionValue}
            id={this.props.id}
            showClear={this.props.showClear}
            options={this.props.options}
            itemTemplate={this.props.itemTemplate}
            emptyMessage="Nenhum registro encontrado"
            emptyFilterMessage="Nenhum registro encontrado"
            style={{ appearence: 'auto' }}
            onHide={this.props.onHide}
            placeholder={this.props.value}
          />
        </span>
      )
    } else {
      return (
        <span className="p-float-label selectinput">
          <Dropdown
            className={this.className()} {...this.props}
            value={this.props.value || ''}
            optionLabel={this.props.optionLabel}
            optionValue={this.props.optionValue}
            autoDisplayFirst="false" id={this.props.id}
            options={this.props.options}
            emptyMessage="Nenhum registro encontrado"
            emptyFilterMessage="Nenhum registro encontrado"
            style={{ appearence: 'auto' }}
            showClear={this.props.showClear}
          />
          <label
            className={this.props.required && !this.props.value ? 'label' : ''}
            htmlFor={this.props.id}
          >
            {this.props.label}
          </label>
        </span>
      )
    }
  }
}

export default SelectInput
