import React, { useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import TextInput from '../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../helpers/useValidateInput'
import SelectInput from '../../../components/inputs/SelectInput'
// import GrupoUsuarioService from '../../../services/cadastro/GrupoUsuarioService'
import CommonHelper from '../../../helpers/CommonHelper'
import GrupoService from '../../../services/cadastro/GrupoService'

const DadosPrincipaisUsu = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)
  const [grupos, setGrupos] = useState([])

  useEffect(async () => {
    await getGrupoUsuario()
    if (form.values.codgru_usu) {
      const grupo = await GrupoService.get(form.values.codgru_usu)
      form.setFieldValue('grupo', grupo)
      form.setFieldValue('codgru_usu', grupo.codigo_gru)
    }
  }, [])

  const getGrupoUsuario = async () => {
    try {
      const grupos = await GrupoService.getTodos()
      setGrupos(grupos)
    } catch (error) {}
  }

  const handleChangeGrupo = (e) => {
    const grupo = e.target.value

    form.validateField('codgru_usu')

    if (grupo) {
      form.setFieldValue('grupo', grupo)
      form.setFieldValue('codgru_usu', grupo.codigo_gru)
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nome_usu')}/>
        <TextInput
          value={form.values.nome_usu}
          name="nome_usu"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nome_usu') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome/Razão Social"
        />
        <FieldErrorMessage message={getFormErrorMessage('nome_usu')}/>
      </div>
      <div className="field col-2">
        <TextInput
          label="CPF"
          name="cpf_usu"
          placeholder="CPF"
          value={form.values.cpf_usu}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cpf_usu') }, 'inputfield w-full')}
          mask="999.999.999-99"
          onBlur={setFieldTouched}
        />
      </div>
      <div className="field col-12 md:col-4">
        <RequiredLabel label="Grupo de usuário" valid={isFormFieldValid('codgru_usu')}/>
        <SelectInput
          value={form.values.grupo === undefined ? 'Selecione' : form.values.grupo.nome_gru }
          options={grupos}
          onChange={handleChangeGrupo}
          name="grupo"
          filter={CommonHelper.isDesktop()}
          optionLabel="nome_gru"
          dataKey="codigo_gru"
          noFloatLabel
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('codgru_usu') }, 'inputfield w-full')}
          onBlur={() => form.setFieldTouched('codgru_usu')}
        />
        <FieldErrorMessage message={getFormErrorMessage('codgru_usu')}/>
      </div>
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Email" valid={isFormFieldValid('email')}/>
        <TextInput
          value={form.values.email}
          name="email"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Email"
        />
        <FieldErrorMessage message={getFormErrorMessage('email')}/>
      </div>
      {!form.values.codigo_usu && (
        <div className="field col-12 md:col-4">
          <RequiredLabel label="Senha" valid={isFormFieldValid('password')}/>
          <TextInput
            type="password"
            value={form.values.password}
            name="password"
            onChange={form.handleChange}
            className={classNames('inputfield w-full')}
            onBlur={setFieldTouched}
            disabled={!edicaoHabilitada}
            placeholder="Senha"
          />
          <FieldErrorMessage message={getFormErrorMessage('password')}/>
        </div>
      )}
      <div className="field checkbox-container col-1">
        <Checkbox
          name="ativo_usu"
          value={form.values.ativo_usu }
          onChange={form.handleChange}
          checked={form.values.ativo_usu === 1 ? true : false}
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
        />
        <label className="p-checkbox-label mb-0 pl-2">Ativo</label>
      </div>
    </div>
  )
}

export default DadosPrincipaisUsu
