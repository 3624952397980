import axios from 'axios'
import { authToken, credentials } from './auth'
import { loc, dev, prod } from '../config'
import { startLoading, stopLoading } from '../redux/actions'
import { dispatch } from '../redux/store'

let config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev

switch (process.env.REACT_APP_STAGE) {
case 'loc':
  config = loc
  break
case 'prod':
  config = prod
  break
default:
  config = dev
}

export const API_URL = config.apiGateway.URL

export function server (loading = true) {
  let connection = axios.create({
    baseURL: API_URL,
    responseType: 'json',
    headers: {
      Authorization: 'Bearer ' + authToken(),
      Credentials: credentials(),
      LoadingState: loading
    }
  })

  connection.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (config.headers['LoadingState']) {
      dispatch(startLoading())
    }

    return config
  }, function (error) {
    // Do something with request error
    if (config.headers['LoadingState']) {
      dispatch(startLoading())
    }

    return Promise.reject(error)
  })

  connection.interceptors.response.use(function (response) {
    // Any status code that lie WITHIN the range of 2xx cause this function to trigger
    // Do something with response data
    dispatch(stopLoading())

    return response
  }, function (error) {
    // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
    // Do something with response error
    dispatch(stopLoading())

    return Promise.reject(error)
  })

  return connection
}
