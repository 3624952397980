import { Component } from 'react'
import { showErrorMessage, showSuccessMessage } from '../components/utils/Message'

export class Crud extends Component {
  constructor (props, Model, Service, resource, primaryKey = 'id') {
    super(props)

    this.model = Model
    this.modelService = new Service()
    this.resource = resource
    this.primaryKey = primaryKey
    this.state = {
      model: Model(),
      dataLoaded: false,
      isNew: false,
      resource: this.resource,
      history: props.history
    }
  }

  async componentDidMount () {
    let id = this.props.match.params.id
    if (id) {
      this.get(id)
    } else {
      this.setState({ isNew: true })
    }
  }

  get = async (id) => {
    try {
      let model = await this.modelService.get(id)

      this.setState({
        model: this.model(model),
        dataLoaded: true
      })
    } catch (e) {
      showErrorMessage('Erro ao obter informações')
    }
  };

  onDelete = async (id) => {
    try {
      const response = await this.modelService.delete(id)
      showSuccessMessage('Registro deletado com sucesso')
      this.props.history.push(`/${this.resource}`)

      return response
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao deletar registro')
    }
  };
}
