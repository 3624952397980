import { postMenuDTO } from '../../dtos/cadastro/MenuDTO'
import ServiceBase from '../base/ServiceBase'

export default class MenuService {
  async get (id) {
    let resultado = await ServiceBase.getBase(`menu/${id}`)
    return resultado
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`menus?page=${page}&perPage=${perPage}`)
  }

  static async getTodos () {
    const res = await ServiceBase.getBase('menus/todos')
    return res
  }

  static async save (data) {
    return await ServiceBase.postBase('menu', postMenuDTO(data))
  }
}
