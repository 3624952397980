import React, { useEffect, useState } from 'react'
import TextInput from '../../components/inputs/TextInput'
import AccordionContainer from '../../components/layout/AccordionContainer'
import AccordionPage from '../../components/layout/AccordionPage'
import Container from '../../components/layout/Container'
import { getStore } from '../../redux/store'
import BiaService from '../../services/cadastro/BiaService'
import { Skeleton } from 'primereact/skeleton'

const BiaHome = () => {
  const [chaveTeste, setChaveTeste] = useState([])
  const usuario = getStore().usuarioLogado
  const [loading, setLoading] = useState(false)
  const nomeMes = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  useEffect(async () => {
    setLoading(true)
    const chave = await BiaService.filter()
    setChaveTeste(chave)
    setLoading(false)
  }, [])
  return (
    <Container col='8'>
      { usuario.codgru_usu === 8 || usuario.codgru_usu === 10 || usuario.codgru_usu === 11 ? loading ? <div className="flex flex-wrap"><div className="w-full h-10 md:w-50 p-6"><Skeleton width="100%" height="50px" borderRadius="16px"></Skeleton> </div> </div> : <form>
        <div className='flex justify-content-between form-header'></div>
        <div className='grid form-body'>
          <AccordionContainer>
            <AccordionPage header={`Chave de teste de ${chaveTeste[0] === undefined ? '' : nomeMes[chaveTeste[0].mes_lgr - 1]} ${chaveTeste[0] === undefined ? '' : `de ${chaveTeste[0].ano_lgr}`} `} active>
              <TextInput
                value={chaveTeste[0] === undefined ? 'Carregando...' : chaveTeste[0].chave_lgr}
                name="chaveteste"
                className={'inputfield w-'}
                disabled={true}
                label="  Chave de teste: "
              />
            </AccordionPage>
          </AccordionContainer>
        </div>
      </form> : '' }
    </Container>
  )
}
export default BiaHome
