import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AccordionContainer from '../../../components/layout/AccordionContainer'
import AccordionPage from '../../../components/layout/AccordionPage'
import Container from '../../../components/layout/Container'
import { baseForm } from '../../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import DadosPrincipaisGruUsu from './DadosPrincipaisGruUsu'
import { GrupoUsuarioValidator } from './GrupoUsuarioValidator'
import GrupoUsuarioService from '../../../services/cadastro/GrupoUsuarioService'

const GrupoUsuarioForm = ({ isNew, model }) => {
  const [isNovo, setIsNovo] = useState(isNew)
  const [edicaoHabilitada, setEdicaoHabilitada] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (isNovo) {
      form.setFieldValue('ativo_gru', true)
      setEdicaoHabilitada(true)
    }
  }, [])

  const handleSubmit = async (data) => {
    try {
      console.log(data)
      const grupoUsuario = await GrupoUsuarioService.save(data)

      history.push('/gruposusuarios/')
      form.setFieldValue('codigo_gru', grupoUsuario.codigo_gru)

      setIsNovo(false)
      showSuccessMessage('Registro salvo com sucesso')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao salvar registro')
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()
    const errors = await form.validateForm(form.values)

    if (Object.keys(errors).length !== 0) {
      form.handleSubmit(e)
      showWarnMessage('Campos obrigatórios não preenchidos!')
    } else {
      form.handleSubmit(e)
    }
  }

  const form = baseForm({
    initialValues: model,
    validationSchema: GrupoUsuarioValidator,
    onSubmit: handleSubmit
  })

  return (
    <Container col="12">
      <form onSubmit={submitForm}>
        <div className="flex justify-content-between form-header">
          <h1>Grupo de Usuário</h1>
          {!isNovo && (
            <div className="flex justify-content-between">
              <Button
                type="button"
                label="Editar"
                className="edit-button"
                icon="pi pi-pencil"
                onClick={() => setEdicaoHabilitada(!edicaoHabilitada)}
              />
            </div>
          )}
        </div>
        <div className="grid form-body">
          <AccordionContainer>
            <AccordionPage header="Informações principais" active>
              <DadosPrincipaisGruUsu
                form={form}
                edicaoHabilitada={edicaoHabilitada}
              />
            </AccordionPage>
          </AccordionContainer>
        </div>
        <div className="flex justify-content-end form-footer">
          {edicaoHabilitada && (
            <Button
              type="submit"
              label="Salvar"
              className="p-button"
            />
          )}
        </div>
      </form>
    </Container>
  )
}

export default GrupoUsuarioForm
