import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import ResponsiveColumn from '../../components/utils/ResponsiveColumn'
import List from '../../classes/List'
import AtualizacaoService from '../../services/cadastro/AtualizacaoService'
import { getAtualizacaoDTO } from '../../dtos/cadastro/AtualizacaoDTO'
import { formatDate, formatDateTime } from '../../helpers/formaters'
import AppButton from '../../components/layout/AppButton'
import { Button } from 'primereact/button'
import Confirm from '../../components/utils/Confirm'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import { getStore } from '../../redux/store'
import { ProgressSpinner } from 'primereact/progressspinner'
import AgendarAtualizacao from './AgendarAtualizacao'
import ApiMovidesk from '../../helpers/ApiMovidesk'

class Atualizacao extends List {
  constructor (props) {
    super(
      props,
      getAtualizacaoDTO,
      AtualizacaoService,
      'atualizacao',
      'codigo_atu',
      'finalizado_atu'
    )

    this.state = {
      ...this.state,
      visibleVeiculoModal: false,
      visibleInfoModal: false,
      visibleSaidaEstoqueMontadoraModal: false,
      // dataDeAgendamento: null,
      dataDeAtualizacao: null,
      veiculoSelecionado: {},
      atualizacao: '',
      selected: '',
      page: 1,
      first: 0,
      IsNew: false,
      model: '',
      atualizacaoParaCancelar: {},
      atualizacao: {},
      model: {}
    }

    this.setColumns([
      {
        key: 'codigo_atu',
        component: (
          <Column
            key={'nomfan_emp'}
            header="EMPRESA"
            field="nomfan_emp"
            sortable
            headerClassName="codigo_atu"
          />
        )
      },
      {
        key: 'VersaoAtual',
        component: (
          <Column
            key={'VersaoAtual'}
            header="VERSÃO ATUAL"
            body={(data) => data.VersaoAtual}
            field="VersaoAtual"
            sortable
            headerClassName="VersaoAtual"
          />
        )
      },
      {
        key: 'VersaoNova',
        component: (
          <Column
            key={'VersaoNova'}
            header="ATUALIZAR PARA"
            body={data => data.VersaoNova}
            field="VersaoNova"
            sortable
            headerClassName="VersaoNova"
          />
        )
      },
      {
        key: 'datage_atu',
        component: (
          <Column
            key={'datage_atu'}
            header="ATUALIZAR DIA"
            body={data => formatDateTime(data.datage_atu)}
            field="datage_atu"
            sortable
            headerClassName="datage_atu"
          />
        )
      },
      {
        key: 'datatu_atu',
        component: (
          <Column
            key={'datatu_atu'}
            header="ATUALIZADO EM"
            body={data => data.datatu_atu && formatDateTime(data.datatu_atu)}
            field="datatu_atu"
            sortable
            headerClassName="datatu_atu"
          />
        )
      },
      {
        key: 'usuarioSol',
        component: (
          <Column
            key={'usuarioSol'}

            header="SOLICITADO POR"
            body={ data => data.usuarioSol }
            field="usuarioSol"
            sortable
            headerClassName="usuarioSol"
          />
        )
      },
      {
        key: 'usuarioAtu',
        component: (
          <Column
            key={'usuarioAtu'}

            header="ATUALIZADO POR"
            body={ data => data.usuarioAtu }
            field="usuarioAtu"
            sortable
            headerClassName="usuarioAtu"
          />
        )
      },
      {
        key: 'finalizado_atu',
        component: (
          <Column
            key={'finalizado_atu'}
            style={{ width: '10%' }}
            header="Situação"
            body={data => {
              let descricaoSituacao = ''
              let descricaoColor = ''
              let dataNow = Date.now()
              let dataHoje = new Date(dataNow)
              switch (data.finalizado_atu) {
              case 1:
                descricaoSituacao = 'ATUALIZADO'
                descricaoColor = 'green'
                break
              case 0:
                if (formatDate(data.datage_atu) < formatDate(dataHoje)) {
                  descricaoSituacao = 'ATUALIZAÇÃO ATRASADA'
                  descricaoColor = 'red'
                } else {
                  descricaoSituacao = 'AGENDADO'
                  descricaoColor = 'yellow'
                }
                break
              }
              return <ResponsiveColumn
                column="finalizado_atu"
                value={descricaoSituacao}
                className={`colored-label ${descricaoColor}`}
              />
            }}
            field="finalizado_atu"
            sortable
            headerClassName="finalizado_atu"
          />
        )
      }
    ])
  }

  handleCancelarAtualizacao = async () => {
    try {
      await AtualizacaoService.delete(this.state.atualizacaoParaCancelar.codigo_atu)
      this.setState({ visibleConfirm: false })
      await this.getAll()
      showSuccessMessage('Atualização cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao cancelar atualização!')
    }
  }

  handleVerificaTickets = async (cnpj) => {
    try {
      let clienteTicket = await ApiMovidesk.getPessoaMovidesk(cnpj)
      let empresaVinculada = await ApiMovidesk.getNomeEmpresa(clienteTicket.data[0].businessName)
      Promise.all(clienteTicket, empresaVinculada).catch(() => console.error(error))
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao cancelar atualização!')
    }
  }

  handleConfirmarAtualizacao = async () => {
    try {
      await AtualizacaoService.save(this.state.atualizacao.codemp_atu, this.state.atualizacao.codveratu_atu, this.state.atualizacao.codvernov_atu, this.state.atualizacao.datage_atu, this.state.atualizacao.observacao_atu, this.state.atualizacao.codigoUsuarioSol, this.state.atualizacao.codigo_atu, getStore().usuarioLogado.codigo_usu, this.state.atualizacao.datatu_atu)
      this.setState({ visibleConfirmAtualizacao: false })
      this.handleVerificaTickets(this.state.atualizacao.cnpj_emp)
      await this.getAll()
      showSuccessMessage('Atualizado com sucesso!')
      // showWarnMessage('Verificando se existe tickets relacionados a essa empresa, favor não recarregar a página!')
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  render () {
    const state = this.state

    const rowExpansionTemplate = (data) => {
      return (
        <>
          <div className="expansion row-expansion flex flex-column justify-content-start align-items-start text-overflow-clip">
            <label>
            Observação:
              <span className="black md:text-overflow-clip" >
                {data.observacao_atu}
              </span>
            </label>
            { data.finalizado_atu ? '' : <div className="flex justify-content-end" style={{ width: '100%', height: '100%' } }>
              <AppButton
                label="Cancelar atualização"
                className="p-button-danger mr-3 mt-3"
                onClick={() => this.setState({ visibleConfirm: true, atualizacaoParaCancelar: data })}
              />
              <AppButton
                label="Reagendar atualização"
                className="p-button-secondary mr-3 mt-3"
                onClick={() => this.setState({ visibleReagendarAtualizacao: true, IsNew: false })}
              />
              <AppButton
                label="Atualizar"
                className="mr-3 mt-3"
                onClick={() => this.setState({ visibleConfirmAtualizacao: true, atualizacao: data })}
              />
            </div>
            }
          </div>
        </>
      )
    }

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })
      switch (option) {
      case 'Agendados':
        filter.finalizado_atu = 'Agendados'
        this.setState({ opcaoSelecionada: option })
        break
      case 'Atualizados':
        filter.finalizado_atu = 'Atualizados'
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Painel de Atualização</h4>
          <div className="page-header-buttons">
            <Button
              label="Agendar atualização"
              onClick={() => this.setState({ visibleReagendarAtualizacao: true, IsNew: true })}
              className="mr-2"
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'finalizado_atu' || this.state.opcaoSelecionada === 'Agendados' }, 'filter-option')}
              onClick={() => onSelectOption('Agendados')}>
              <span className="option-label">Agendados</span>
              <div className="option-quantity">
                {state.quantidades.Agendados}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'Atualizados' }, 'filter-option')}
              onClick={() => onSelectOption('Atualizados')}>
              <span className="option-label">Atualizados</span>
              <div className="option-quantity">
                {state.quantidades.Atualizados}
              </div>
            </div>
          </div>
        </div>
        <Container>
          {this.loading ? <div className="loading-container">
            <ProgressSpinner />
          </div> : <DataList
            model={state.model}
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Empresa"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            {this.columns.map((column) => column.component)}
          </DataList> }
        </Container>
        <Confirm
          title="Cancelar atualização"
          visible={this.state.visibleConfirm}
          onCancel={() => this.setState({ visibleConfirm: false })}
          onConfirm={() => this.handleCancelarAtualizacao()}
          description="Confirma cancelar a atualização?"
          cancelLabel="Não"
        />
        <Confirm
          title="Confirmar atualização"
          visible={this.state.visibleConfirmAtualizacao}
          onCancel={() => this.setState({ visibleConfirmAtualizacao: false })}
          onConfirm={() => this.handleConfirmarAtualizacao()}
          description="Confirma atualização?"
          cancelLabel="Não"
        />

        <AgendarAtualizacao
          visible={state.visibleReagendarAtualizacao}
          onHide={() => this.setState({ visibleReagendarAtualizacao: false })}
          empresaSelect={ state.selected }
          isNew={state.IsNew}
          model={state.model}
        />
      </Page>
    )
  }
}

export default Atualizacao
