import React, { Component } from 'react'
import TextInput from '../../components/inputs/TextInput'
import { Button } from 'primereact/button'
import LoginService from '../../services/publicos/LoginService'
import { Toast } from 'primereact/toast'
import { login } from '../../helpers/auth'
import queryString from 'query-string'
import CommonHelper from '../../helpers/CommonHelper'
import RedefinirSenhaModal from './RedefinirSenhaModal'
import ChangePasswordObrigatorioModal from '../../components/utils/ChangePasswordObrigatorioModal'

export class Login extends Component {
  constructor (props) {
    super(props)
    this.loginService = new LoginService()
    this.state = {
      senha: '',
      email: '',
      loading: false,
      usuario: {}
    }
  }

  handleQueryParamas = () => {
    if (!this.props.location.search) return

    const { email } = queryString.parse(this.props.location.search)
    this.setState({ email })
  }

  componentDidMount () {
    this.handleQueryParamas()
  }

  handleKeyPress = e => {
    if (e.keyCode === 13) { // Tecla ENTER
      this.onLoginAcesso()
    }
  }

  onLoginAcesso = async () => {
    this.setState({ loading: true })
    try {
      this.setState({
        loading: false
      }, this.onLogin)
    } catch (e) {
      this.setState({ loading: false })
      this.toast.show({
        severity: 'error',
        summary: 'Login/Senha inválidos',
        detail: e.message || 'Favor confira seus dados e tente novamente'
      })
    }
  }

  onLogin = async () => {
    try {
      let token = await this.loginService.login({
        password: this.state.senha,
        email: this.state.email
      })
      login(token)
      this.props.history.push('/home')
    } catch (e) {
      this.toast.show({
        severity: 'error',
        summary: 'Erro',
        detail: e.message || 'Ops! Ocorreu um erro ao fazer o login!'
      })
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleConfirm = () => {
    this.setState({ visibleCompanySelect: false })
    this.onLogin()
  }

  render () {
    const { senha, email, loading } = this.state

    return (
      <>
        <div className="login-page">
          <div className="login-body">
            {CommonHelper.isDesktop() && (
              <div className="field col-7 login-logo">
                <div className="logo">
                  <object data="assets/layout/images/logo_sances.png"></object>
                </div>
                <div className="logo-body">
                  <h1 className="logo-welcome">Bem-vindo a plataforma do suporte da sances!</h1>
                  <object data="assets/layout/images/Service 24_7-amico.svg" className="homem-na-poltrona"></object>
                </div>
              </div>
            )}
            <div className={(CommonHelper.isDesktop() ? 'field col-5' : '') + ' login-form'}>

              <object className="mb-5 icone-login" data="/assets/layout/images/icone_login.svg"></object>
              <div className="formgrid grid justify-content-center ">
                <h3 className="login-welcome">Login</h3>
                <div className="field col-12">
                  <TextInput
                    label="Usuário"
                    name="email"
                    value={email}
                    type="email"
                    className="inputfield w-full"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress} />
                </div>
                <div className="field col-12" style={{ marginBottom: '0px' }}>
                  <TextInput
                    type="password"
                    label="Senha"
                    name="senha"
                    value={senha}
                    className="inputfield w-full"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress} />
                </div>
                <div className="flex justify-content-end" style={{ width: '100%' }}>
                  {/* <small className="login-smaller-link  mr-3 mb-4" onClick={() => this.setState({ visibleRedefinirSenhaModal: false })}>Esqueci minha senha.</small> */}
                </div>
                <div className="field col-12 no-margin" style={{ padding: '10px' }}>
                  <Button
                    className="login-button w-full mr-4 mb-6"
                    label="Entrar"
                    icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
                    onClick={this.onLoginAcesso}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
        <RedefinirSenhaModal
          visible={this.state.visibleRedefinirSenhaModal}
          onHide={() => this.setState({ visibleRedefinirSenhaModal: false })}
        />
        <ChangePasswordObrigatorioModal
          visible={this.state.visibleAlterarSenhaModal}
          usuario={this.state.usuario}
          hide={(novaSenha) => {
            this.setState({ visibleAlterarSenhaModal: false, senha: novaSenha })
            this.onEmpresasAcesso()
          }}
        />

        <Toast ref={(el) => this.toast = el} />
      </>
    )
  }
}
