export const useValidateInput = (formik) => {
  const isFormFieldValid = (field) => {
    const [name, attribute, childAttribute] = field.split('.')
    if (attribute && formik.touched[name]) {
      if (formik.touched[name]) {
        if (formik.errors[name]) {
          return field.split('.').length === 2 ? !!(formik.errors[name][attribute]) : !!(formik.errors[name][attribute][childAttribute])
        }
      }
    }
    // if (attribute && formik.touched[name]) {
    return !!(formik.touched[name] && formik.errors[name])
    // } else {
    //   return false
    // }
  }

  const getFormErrorMessage = (field) => {
    const [name, attribute, childAttribute] = field.split('.')

    if (attribute && isFormFieldValid(name)) {
      return field.split('.').length === 2 ? formik.errors[name][attribute] : formik.errors[name][attribute][childAttribute]
    }

    return isFormFieldValid(name) && formik.errors[name]
  }

  return {
    isFormFieldValid,
    getFormErrorMessage
  }
}
