import * as Yup from 'yup'

export const EmpresaValidator = Yup.object().shape(
  {
    nomraz_emp: Yup.string()
      .nullable()
      .required('Este campo é obrigatório!'),
    codver_emp: Yup.number()
      .nullable()
      .required('Este campo é obrigatório!'),
    nomfan_emp: Yup.string()
      .nullable()
      .required('Este campo é obrigatório!'),
    cnpj_emp: Yup.string()
      .nullable()
      .required('Este campo é obrigatório!'),
    senhabanco_emp: Yup.string()
      .nullable()
      .required('Este campo é obrigatório!')
  })
