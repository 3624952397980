import { SET_CERTIFICADO_VALIDO, SET_EMPRESA_LOGADA, SET_MENSAGEM_GLOBAL, SET_USUARIO_LOGADO, START_LOADING, STOP_LOADING } from './types'

export const setUsuarioLogado = (payload) => {
  return { type: SET_USUARIO_LOGADO, payload }
}

export const setEmpresaLogada = (payload) => {
  return { type: SET_EMPRESA_LOGADA, payload }
}

export const setMensagemGlobal = (payload) => {
  return { type: SET_MENSAGEM_GLOBAL, payload }
}

export const setCertificadoValido = (payload) => {
  return { type: SET_CERTIFICADO_VALIDO, payload }
}

export const startLoading = () => {
  return { type: START_LOADING }
}

export const stopLoading = () => {
  return { type: STOP_LOADING }
}
