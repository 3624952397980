export const getMenuDTO = (data = {}) => {
  return {

    codigo_men: data.codigo_men || null,
    descri_men: data.descri_men || null
  }
}

export const postMenuDTO = (data = {}) => {
  return {
    codigo_men: data.codigo_men || null,
    descri_men: data.descri_men || null
  }
}
