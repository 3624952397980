import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import TextInput from '../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../helpers/useValidateInput'

const DadosPrincipaisTipoAcesso = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-9">
        <RequiredLabel label="Nome" valid={isFormFieldValid('nome_tia')}/>
        <TextInput
          value={form.values.nome_tia}
          name="nome_tia"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nome_tia') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome"
        />
        <FieldErrorMessage message={getFormErrorMessage('nome_tia')}/>
      </div>
      <div className="field checkbox-container col-1">
        <Checkbox
          name="ativo_tia"
          value={form.values.ativo_tia}
          onChange={form.handleChange}
          checked={form.values.ativo_tia}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-2">Ativo</label>
      </div>
    </div>
  )
}

export default DadosPrincipaisTipoAcesso
