import React, { useEffect, useState } from 'react'
import Modal from '../../../components/utils/Modal'
import { getAcessoEmpDTO, postAcessoEmpDTO } from '../../../dtos/cadastro/AcessoEmpDTO'
import { baseForm } from '../../../components/utils/BaseForm'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import { DadosAcessoValidator } from './DadosAcessoValidator'
import TipoAcessoService from '../../../services/cadastro/TipoAcessoService'
import { useValidateInput } from '../../../helpers/useValidateInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import TextInput from '../../../components/inputs/TextInput'
import { classNames } from 'primereact/utils'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import SelectInput from '../../../components/inputs/SelectInput'
import CommonHelper from '../../../helpers/CommonHelper'
import EmpresaService from '../../../services/cadastro/EmpresaService'

function DadosAcessoEmpresaModal ({ visible, onHide, empresaForm, acessoEmpSelecionado, edicaoHabilitada }) {
  const [tipoAcessos, setTiposAcessos] = useState([])

  const form = baseForm({
    initialValues: getAcessoEmpDTO(),
    validationSchema: DadosAcessoValidator,
    onSubmit: onSubmit
  })

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => {
    form.setFieldTouched(e.target.name)
  }
  async function hideModal () {
    onHide()
  }
  async function onSubmit () {
    try {
      // const errors = await form.validateForm(form.values)

      // if (Object.keys(errors).length !== 0) {
      //   form.handleSubmit(e)
      //   showWarnMessage('Campos obrigatórios não preenchidos!')
      // } else {
      //   form.handleSubmit(e)
      // }
      insertOrUpdateDadosAcessoEmp()
      await hideModal()
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  function insertOrUpdateDadosAcessoEmp () {
    if (acessoEmpSelecionado) {
      updateAcessoEmp()
      return
    }

    insertAcessoEmp()
  }

  function updateAcessoEmp () {
    const acessosemp = empresaForm.values.acessosemp

    const indexAcessoEmp = acessosemp.indexOf(acessoEmpSelecionado)

    acessosemp.splice(indexAcessoEmp, 1, postAcessoEmpDTO(form.values))

    empresaForm.setFieldValue('acessosemp', acessosemp)
  }

  function insertAcessoEmp () {
    const acessosemp = empresaForm.values.acessosemp

    acessosemp.push(postAcessoEmpDTO(form.values))

    empresaForm.setFieldValue('acessosemp', acessosemp)
  }

  async function excluirAcessoEmp () {
    const acessosemp = empresaForm.values.acessosemp

    const indexAcessoEmp = acessosemp.indexOf(acessoEmpSelecionado)

    acessosemp.splice(indexAcessoEmp, 1)

    empresaForm.setFieldValue('acessosemp', acessosemp)

    await hideModal()
  }

  async function baixarWts () {
    try {
      let txtArqBase64 = await EmpresaService.downloadWts(acessoEmpSelecionado.codigo_ace)
      CommonHelper.downloadFile('data:application/txt;base64,', txtArqBase64, `rdp-${acessoEmpSelecionado.nome_ace}.rdp`)
      showSuccessMessage('Arquivo WTS baixado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer download do arquivo XML')
    }
  }

  useEffect(async () => {
    if (visible) {
      if (acessoEmpSelecionado) {
        const dadosacessoEmpSelecionado = getAcessoEmpDTO(acessoEmpSelecionado)
        await form.setValues(dadosacessoEmpSelecionado)
        if (dadosacessoEmpSelecionado.codtia_ace) {
          const tipoacesso = await TipoAcessoService.get(dadosacessoEmpSelecionado.codtia_ace)
          form.setFieldValue('tipoacesso', tipoacesso)
          form.setFieldValue('codtia_ace', tipoacesso.codigo_tia)
        }
      } else {
        form.resetForm()
      }

      await getTipoAcesso()
    }
  }, [visible])

  const getTipoAcesso = async () => {
    try {
      const tipoAcessos = await TipoAcessoService.getTodos()
      setTiposAcessos(tipoAcessos)
    } catch (error) { }
  }

  const handleChangeTipoAcesso = (e) => {
    const tipoacesso = e.target.value

    form.validateField('codtia_ace')
    if (tipoacesso) {
      form.setFieldValue('tipoacesso', tipoacesso)
      form.setFieldValue('codtia_ace', tipoacesso.codigo_tia)
    }
  }

  return (
    <Modal
      header="Acessos da empresa"
      width={45}
      visible={visible}
      onHide={async () => await hideModal()}
      btnSalvar={edicaoHabilitada ? form.submitForm : undefined}
      btnExcluir={(acessoEmpSelecionado && edicaoHabilitada) ? excluirAcessoEmp : undefined}
      btnBaixar={(acessoEmpSelecionado && acessoEmpSelecionado.codtia_ace === 5 || acessoEmpSelecionado && acessoEmpSelecionado.codtia_ace === 2) ? baixarWts : undefined}
    >
      <div className="formgrid grid">

        <div className="field col-12">
          <RequiredLabel label="Nome" valid={isFormFieldValid('nome_ace')} />
          <TextInput
            placeholder="Nome do Acesso"
            name="nome_ace"
            value={form.values.nome_ace}
            onChange={form.handleChange}
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('nome_ace') }, 'inputfield w-full')}
            onBlur={setFieldTouched}
            form={form}
          />
          <FieldErrorMessage message={getFormErrorMessage('nome_ace')} />
        </div>
        <div className="field col-12 md:col-12">
          <RequiredLabel label="Tipo de acesso" valid={isFormFieldValid('codtia_ace')}/>
          <SelectInput
            value={form.values.tipoacesso === null ? form.values.tipoacesso : form.values.tipoacesso.nome_tia}
            options={tipoAcessos}
            onChange={handleChangeTipoAcesso}
            name="tipoacesso"
            filter={CommonHelper.isDesktop()}
            placeholder={'Selecione'}
            optionLabel="nome_tia"
            dataKey="nome_tia"
            noFloatLabel
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('codtia_ace') }, 'inputfield w-full')}
            onBlur={() => form.setFieldTouched('codtia_ace')}
          />
          <FieldErrorMessage message={getFormErrorMessage('codtia_ace')}/>
        </div>

        <div className="field col-12">
          {/* <RequiredLabel label="Gateway" valid={isFormFieldValid('gateway_ace')} /> */}
          <TextInput
            label= "Gateway"
            placeholder="Gateway"
            name="gateway_ace"
            value={form.values.gateway_ace}
            onChange={form.handleChange}
            disabled={!edicaoHabilitada}
            className={classNames({ 'p-invalid': isFormFieldValid('gateway_ace') }, 'inputfield w-full')}
            onBlur={setFieldTouched}
            form={form}
          />
          <FieldErrorMessage message={getFormErrorMessage('gateway_ace')} />
        </div>

        <div className="field col-12 md:col-8">
          <RequiredLabel label="IP" valid={isFormFieldValid('ip_ace')}/>
          <TextInput
            name="ip_ace"
            value={form.values.ip_ace}
            onChange={form.handleChange}
            disabled={!edicaoHabilitada}
            onBlur={setFieldTouched}
            className={classNames({ 'p-invalid': isFormFieldValid('ip_ace') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('ip_ace')}/>
        </div>

        <div className="field col-12 md:col-4">
          <TextInput
            label="Usuário"
            name="usuario_ace"
            value={form.values.usuario_ace}
            onChange={form.handleChange}
            disabled={!edicaoHabilitada}
            onBlur={setFieldTouched}
            className={classNames({ 'p-invalid': isFormFieldValid('usuario_ace') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('usuario_ace')} />
        </div>

        <div className="field col-6">
          <RequiredLabel label="Senha de acesso" valid={isFormFieldValid('senha_ace')} />
          <TextInput
            placeholder="Senha de Acesso"
            name="senha_ace"
            value={form.values.senha_ace}
            onChange={form.handleChange}
            disabled={!edicaoHabilitada}
            onBlur={setFieldTouched}
            className={classNames({ 'p-invalid': isFormFieldValid('senha_ace') }, 'inputfield w-full')}
          />
          <FieldErrorMessage message={getFormErrorMessage('senha_ace')}/>
        </div>
        <div className="field col-6">
          <TextInput
            label="Senha computador"
            placeholder="Senha do Computador"
            name="senhacomp_ace"
            value={form.values.senhacomp_ace}
            onChange={form.handleChange}
            disabled={!edicaoHabilitada}
            onBlur={setFieldTouched}
            className={classNames('inputfield w-full')}
          />
        </div>

      </div>
    </Modal>
  )
}

export default DadosAcessoEmpresaModal
