import * as Yup from 'yup'

export const UsuarioValidator = Yup.object().shape({
  nome_usu: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  email: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  codgru_usu: Yup.number()
    .nullable()
    .required('Este campo é obrigatório!')
})
