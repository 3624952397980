export const getTipoAcessoDTO = (data = {}) => {
  return {

    codigo_tia: data.codigo_tia || null,
    nome_tia: data.nome_tia || null,
    datins_tia: data.datins_tia || null,
    datalt_tia: data.datalt_tia || null,
    ativo_tia: data.ativo_tia === 1 ? true : false,
    codusuins_tia: data.codusuins_tia || null,
    codusualt_tia: data.codusualt_tia || null,
    usuarioAlteracao: data.usuarioAlteracao || null,
    usuarioInsercao: data.usuarioInsercao || null
  }
}

export const postTipoAcessoDTO = (data = {}) => {
  return {
    codigo_tia: data.codigo_tia || null,
    nome_tia: data.nome_tia || null,
    datins_tia: data.datins_tia || null,
    datalt_tia: data.datalt_tia || null,
    ativo_tia: data.ativo_tia ? 1 : 0,
    codusuins_tia: data.codusuins_tia || null,
    codusualt_tia: data.codusualt_tia || null,
    usuarioAlteracao: data.usuarioAlteracao || null,
    usuarioInsercao: data.usuarioInsercao || null
  }
}
