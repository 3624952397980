import * as Yup from 'yup'

export const DadosAcessoValidator = Yup.object().shape({
  nome_ace: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  codtia_ace: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  ip_ace: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!'),
  senha_ace: Yup.string()
    .nullable()
    .required('Este campo é obrigatório!')
})
