import React, { Component } from 'react'

export class PublicFooter extends Component {
  render () {
    return (
      <div className="layout-footer-login">
        <div className="footer-text-login" >© {new Date().getFullYear()} Suporte</div>
      </div>
    )
  }
}
