import React, { Component } from 'react'

export class AppProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <></>
    )
  }
}
