import React, { useEffect, useState } from 'react'
import Container from '../../../components/layout/Container'
import TabMenuContainer from '../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../components/layout/TabMenuContainer/TabMenuPage'
import { showErrorMessage } from '../../../components/utils/Message'
import EmpresaService from '../../../services/cadastro/EmpresaService'
import ConfigService from '../../../services/configuracao/ConfigService'
import ConfiguracaoPorConfig from './ConfiguracaoPorConfig'
import ConfiguracaoPorEmpresa from './ConfiguracaoPorEmpresa'

const ConfiguracaoList = () => {
  const [empresaInitialValue, setEmpresaInitialValue] = useState({})
  const [configInitialValue, setConfigInitialValue] = useState({})
  const [listConfig, setListConfig] = useState({})

  useEffect(() => {
    getConfigsAll()
    getEmpresaAll()
  }, [])

  const getConfigsAll = async () => {
    try {
      let config = new ConfigService()
      let response = await config.filter('', 1, 10)
      setConfigInitialValue(response.data[0])
      setListConfig(response)

      // getConfig(response.data[0].numero_con)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os registros!')
    }
  }

  const getEmpresaAll = async () => {
    try {
      let empresa = new EmpresaService()
      let response = await empresa.getAll(1, 10)
      setEmpresaInitialValue(response.data[0])
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os registros!')
    }
  }

  return (

    <Container col="12">
      <div className="flex justify-content-between form-header">
        <h1>Configurações</h1>
      </div>
      <div className="grid form-body">
        <TabMenuContainer>
          <TabMenuPage header="POR EMPRESA">
            <ConfiguracaoPorEmpresa empresaInicial={empresaInitialValue} />
          </TabMenuPage>
          <TabMenuPage header="POR CONFIGURAÇÃO">
            <ConfiguracaoPorConfig configInicial={configInitialValue} listConfig={listConfig}/>
          </TabMenuPage>
        </TabMenuContainer>

      </div>
    </Container>
  )
}

export default ConfiguracaoList
