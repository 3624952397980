import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import TextAreaInput from '../../../components/inputs/TextAreaInput'
import TextInput from '../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import CommonHelper from '../../../helpers/CommonHelper'
import { useValidateInput } from '../../../helpers/useValidateInput'
import EmpresaService from '../../../services/cadastro/EmpresaService'
import SelectInput from '../../../components/inputs/SelectInput'
import VersaoService from '../../../services/cadastro/VersaoService'

const DadosPrincipaisEmp = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)
  const [versao, setVersao] = useState([])
  useEffect(async () => {
    await getVersoes()
  }, [])

  const getVersoes = async () => {
    try {
      const versoes = await VersaoService.getAll()
      setVersao(versoes)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeVersao = (e) => {
    const versao = e.target.value
    if (versao) {
      form.setFieldValue('versao', versao)
      form.setFieldValue('codver_emp', versao.codigo_ver)
    }
  }
  function htmlDecode (input) {
    var doc = new DOMParser().parseFromString(input, 'text/html')
    return doc.documentElement.textContent
  }
  const handleChangeCnpj = async (e) => {
    const cnpj = e.target.value
    form.validateField('cnpj_emp')
    if (cnpj != null && cnpj !== '' && CommonHelper.validateCnpj(cnpj) && (form.values.nomraz_emp === null || form.values.nomraz_emp === '')) {
      let emp = new EmpresaService()
      let empresa = await emp.getEmpresaMovidesk(cnpj)
      if (empresa && empresa.data.length > 0) {
        form.setFieldValue('nomraz_emp', empresa.data[0].corporateName)
        form.setFieldValue('nomfan_emp', empresa.data[0].businessName)
        form.setFieldValue('obs_emp', htmlDecode(empresa.data[0].observations))
        form.setFieldValue('ativo_emp', empresa.data[0].isActive)
      }
    }
    form.setFieldValue('cnpj_emp', cnpj)
  }

  return (
    <div className="formgrid grid">
      <div className="field col-3">
        <RequiredLabel label="CNPJ" valid={isFormFieldValid('cnpj_emp')}/>
        <TextInput
          name="cnpj_emp"
          placeholder="CNPJ"
          value={form.values.cnpj_emp}
          onChange={handleChangeCnpj}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cnpj_emp') }, 'inputfield w-full search-cnpj-input')}
          mask="99.999.999/9999-99"
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('cnpj_emp')}/>
      </div>
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomraz_emp')}/>
        <TextInput
          value={form.values.nomraz_emp}
          name="nomraz_emp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomraz_emp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome/Razão Social"
        />
        <FieldErrorMessage message={getFormErrorMessage('nomraz_emp')}/>
      </div>
      <div className="field col-12 md:col-3">
        <RequiredLabel label="Versão" valid={isFormFieldValid('codver_emp')}/>
        <SelectInput
          value={form.values.versao.nome_ver}
          options={versao.data}
          onChange={handleChangeVersao}
          name="codver_emp"
          filter={CommonHelper.isDesktop()}
          optionLabel="nome_ver"
          dataKey="codver_emp"
          onBlur={setFieldTouched}
          noFloatLabel
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('codver_emp') }, 'inputfield w-full')}
        />
        <FieldErrorMessage message={getFormErrorMessage('codver_emp')}/>
      </div>

      <div className="field col-12 md:col-4">
        <RequiredLabel label="Nome fantasia" valid={isFormFieldValid('nomfan_emp')}/>
        <TextInput
          value={form.values.nomfan_emp}
          name="nomfan_emp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomfan_emp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome fantasia"
        />
        <FieldErrorMessage message={getFormErrorMessage('nomfan_emp')}/>
      </div>

      <div className="field col-12 md:col-4">

        <TextInput
          label="Ip do banco"
          value={form.values.ipbanco_emp}
          name="ipbanco_emp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('ipbanco_emp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Ip do banco"
        />
      </div>

      <div className="field col-12 md:col-4">
        <RequiredLabel label="Senha do banco" valid={isFormFieldValid('senhabanco_emp')}/>
        <TextInput
          value={form.values.senhabanco_emp}
          name="senhabanco_emp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('senhabanco_emp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Senha do banco"
        />
        <FieldErrorMessage message={getFormErrorMessage('senhabanco_emp')}/>
      </div>

      <div className="field col-12 md:col-12">

        <TextAreaInput
          label="Observações"
          value={form.values.obs_emp}
          name="obs_emp"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('obs_emp') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Observações"
        />
      </div>

      <div className="field checkbox-container col-12">
        <Checkbox
          name="ativo_emp"
          value={form.values.ativo_emp}
          onChange={form.handleChange}
          checked={form.values.ativo_emp}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-2">Ativo</label>
      </div>
    </div>
  )
}

export default DadosPrincipaisEmp
